
  .link-button {
    background: linear-gradient(to right, #0d6efd, #1cc7d0, #00c2a8);
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    margin-bottom: 10px;
    color: #FFFFFF;
    cursor: pointer;
    flex-shrink: 0;
    font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
    font-size: 18px;
    font-weight: 500;
    height: 4rem;
    padding: 0 1.6rem;
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
    transition: all .5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .link-button:hover {
    box-shadow: rgba(80, 63, 205, 0.8) 0 10px 30px;
    transition-duration: .1s;
  }
  
  @media (min-width: 768px) {
    .link-button {
      padding: 0 2.6rem;
    }
  }