/* Profile Card */
.profile-card {
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
  }
  
  .profile-image-container {
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative; /* If you want text inside of it */
  }
  
  .profile-image-container img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    max-width: 100%;
    height: auto;
  }
  
  .profile-info {
    padding: 16px;
  }
  
  .profile-info h3 {
    margin: 0;
    padding: 0;
    color: black;
    font-size: 1.5em;
    margin-bottom: 8px;
  }
  
  .profile-info p {
    margin: 4px 0;
    color: #333;
  }
  
  .subscription-info {
    background-color: #f3f4f6;
    border-radius: 16px;
    display: inline-block;
    padding: 4px 8px;
    margin-top: 8px;
  }
  
  .subscription-info span {
    font-size: 0.9em;
    color: black;
  }
  