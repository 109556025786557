.content-dynamic-pricing{
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
}

.card {
    background-color: white;
    padding: 10px 20px 11px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #afafaf;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  }
  .pay-button{
    padding: 0.7rem 2rem;
    width: 100%;
    margin: 1rem 0;
    color: white;
    font-weight: bold;
    font-size: medium;
    background-color: #556cd6;
    border: 0;
    border-radius: 5px;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    transition: box-shadow 500ms;
    cursor: pointer;
  }
  .pay-button:disabled{
    background-color: #afafaf;
    box-shadow: none;
    cursor: default;
  }
  .pay-button:disabled:hover{
    box-shadow: none;
  }
  .pay-button:hover{
    box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
  }

/* Pricing table css */

section.pricing {
  background: #cfcfcf;
  background: linear-gradient(to right, #f1f1f1, #e4e4e4);
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
}

.pricing-card {
  width: 82vw;
}

.pricing .card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: .1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.feature-2 {
  text-align: left;
}
.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s;
}

@media (max-width: 700px) {
  .feature-description{
    display: none;
  }

}



/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -.25rem;
    margin-bottom: .25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }

  .pricing .btn-credits:hover {
    opacity: 1;
  }
}

.btn-credits {
  background: linear-gradient(to right, #0d6efd, #1cc7d0, #00c2a8);
}

.card-title2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #212529;
  margin-bottom: 2rem;
}

.card-text2 {
  font-size: 20px;
  line-height: 1.5;
  color: #3e3e3e;
  text-align: center;
  margin-bottom: 30px;
  padding: 0 50px;
  font-family: 'Archivo', sans-serif;
}

