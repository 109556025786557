body {
    margin: 0;
    padding: 0;
    font-family: Prompt;
  }
  
  
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .App-header {
    background-color: #18132F;  /* A color that harmonizes with #0D071C */
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
  }
  
  .header-left, .header-right {
    display: flex;
    align-items: center;
    margin-inline: 2rem;
  }

  .pricing-link {
    margin-right: 20px; /* Add some spacing between the link and the button */
    color: #fff; /* Set the text color */
    text-decoration: none; /* Remove underline */
    font-weight: bold; /* Make it bold */
    cursor: pointer; /* Cursor style */
  }

  .share-link {
    margin-right: 20px; /* Add some spacing between the link and the button */
    color: #313030; /* Set the text color */
    text-decoration: none; /* Remove underline */
    font-weight: normal; /* Make it bold */
    cursor: pointer; /* Cursor style */
  }
  
  
  .cta-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .cta-button:hover{
    background-color: #007bff88;
  }

  .not-display{
    display: none;
  }

  .cta-button:disabled {
    background-color: grey;
    cursor: not-allowed;
  }
  
  .App-main {
    flex: 1;
    display: flex;
    background: linear-gradient(to bottom, #0D071C, #18132F);
    padding: 1rem;
    color: #fff;
  }
  
  .sideContent {
    flex-basis: 30%;
    padding: 20px;
  }
  
  .preview {
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }
  
  
  .drop-box1 {
    border: 2px dashed #fff;
    background-color: #34333b;
    color: white;
    padding: 20px;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
  }

.drop-box1:hover {
    background-color: rgb(32, 34, 38);
}

.text-gym1 {
    color: rgb(234, 234, 234);
    font-family: 'Inter', sans-serif;
}
  
  .text-dropzone {
    color: #fff;
    font-size: 16px;
  }

  .upload-file-card3 {
    background-color:#18102d;
    width: 100%;
    height: 70px;
    border-radius: 5px;
    border: 0.5px solid #d9d9d992;
    display: inline-flex;
    align-items: center;
    padding: 10px;
}

.text-gym-file1 {
    color: rgb(251, 251, 251);
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
}

.file-icon1 {
    font-size: 1.5rem;
    color: rgb(200, 200, 200);
}

.x-icon1 {
    font-size: 2rem;
    color: #bdbdbd;
}

.train-free-button{
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 30px;
}

  .iframe-container {
    position: relative;
    width: 70%;
   
    margin-top: 10px;
    border-radius: 15px;
  }
  
  .responsive-iframe {
    
    border-radius: 15px;
    width: 100%;
    height: 580px;
    border: none;
  }

  .get-link {
    width: 80%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 20px;
  }

  .get-link p{
    color: black;
  }
  
  .link-container,
  .qr-container {
    margin-top: 15px;
  }
  
  .share-link-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    width: 80%;
    text-overflow: ellipsis;
  }

  .separator {
    height: 0.5px;  /* Thickness of the line */
    background-color: rgba(108, 107, 107, 0.531);  /* Color of the line */
    margin-top: 10px;  /* Space above the line */
    margin-bottom: 20px;  /* Space below the line */
  }
  
  .url-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .copy-button {
    margin-left: 10px;
  }
  
  .qr-section {
    margin-top: 10px;
  }
  

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-top: 20px;
  }
  
  .grid-item {
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #acacac3b;
    background-color: #fff;
    padding: 10px;
    cursor: pointer;
  }

  .grid-item small {
    color: #0D071C;
    margin-top: 5px;
    font-size: 0.7rem;
    font-weight: 500;
  }

  .grid-item,.hero small {
    color: #ffffff;
    margin-top: 5px;
    font-size: 0.7rem;
    font-weight: 500;
  }

  .hero{
    background-color: #6836e4;
  }

  .selected {
    border: 2px solid #3f81ec; /* Choose the border color you like */
    scale: 0.98;
  }
  
  .grid-item img {
    width: 60px;  /* set width */
    height: 60px; /* set height */
    object-fit: cover; /* resize image to cover the container while maintaining its aspect ratio */
  }

  .custom-textarea-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom-textarea-container h5 {
   padding-bottom: 20px;
}

.custom-textarea-container textarea {
    width: 100%;
    max-width: 500px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    font-size: 1em;
    line-height: 1.5em;
    resize: vertical; /* allows the user to vertically resize the textarea */
}

  
  .App-footer {
    background-color: #0D071C;  /* Same as header for consistency */
    padding: 1rem;
    text-align: center;
    color: #a7a7a7;
  }

  .email-link {
    color: #fff;  /* Same as footer text for consistency */
    text-decoration: underline;  /* Helps indicate it's a clickable email link */
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensures the popup is above other elements */
}

.popup-inner {
    position: relative;
    padding: 20px;
    width: 80%; /* Adjust the width as necessary */
    max-width: 600px; /* Adjust maximum width as necessary */
    background: white;
    text-align: center;
}

.close-btn {
    position: absolute;
    top: -5px;
    right: -2px;
    border: none;
    background: none;
    font-size: 1.4em;
    color: #333;
}

iframe {
    width: 100%;
    height: 450px; /* Adjust the height as necessary */
    border: none;
}

  
  /* Responsive */
  @media (max-width: 768px) {
    .App-main {
      flex-direction: column;  /* Stack in column on small screens */
      padding: 1rem;
    }
    .sideContent, .preview {
      flex-basis: 100%;  /* Full width on small screens */
    }

    .iframe-container{
        width: 100%;
    }
    .notificationBar{
      font-size: 12px!important;
    }
  }
  