.chat-manager-wrapper {
    position: relative;
  }
  
  .chat-manager-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    background: #fff;
  }

  .chat-manager-dropdown label {
    cursor: pointer;
  }
  
  .hidden {
    display: none;
  }
  