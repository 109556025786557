.react-chatbot-kit-user-chat-message-container {
    display: flex;
    margin: 15px 0;
    justify-content: flex-end;
  }
  
  .react-chatbot-kit-user-avatar-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 12.5px;
    background-color: #3d4e8d;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .react-chatbot-kit-user-avatar-icon {
    fill: #fff;
    width: 15px;
    height: 15px;
  }
  
  .react-chatbot-kit-user-chat-message {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    color: #585858;
    font-weight: medium;
    position: relative;
    text-align: left;
  }
  
  .react-chatbot-kit-user-chat-message-arrow {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #f1f1f1;
    position: absolute;
    right: -7px;
    top: 13px;
  }
  
  .react-chatbot-kit-chat-bot-message-container {
    display: flex;
    margin: 15px 0;
    justify-content: flex-start;
  }
  
  .react-chatbot-kit-chat-bot-avatar-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 1.2rem;
    margin-right: 12.5px;
    background-color: #d8d8d8;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .react-chatbot-kit-chat-bot-avatar-icon {
    fill: #494646;
    width: 22.5px;
    height: 22.5px;
  }
  
  .react-chatbot-kit-chat-bot-avatar-letter {
    color: #1d1d1d;
    margin: 0;
    padding: 0;
  }
  
  .react-chatbot-kit-chat-bot-message {
    background-color: #2898ec;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    color: #fff;
    font-weight: medium;
    position: relative;
    width: 184.5px;
    margin-left: auto;
    text-align: left;
  }
  
  .react-chatbot-kit-chat-bot-message-arrow {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #2898ec;
    position: absolute;
    left: -7px;
    top: 13px;
  }
  
  .react-chatbot-kit-chat-bot-loading-icon-container {
    height: 17px;
    width: 25px;
  }
  
  .chatbot-loader-container {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  
  #chatbot-loader #chatbot-loader-dot1 {
    animation: load 1s infinite;
  }
  
  #chatbot-loader #chatbot-loader-dot2 {
    animation: load 1s infinite;
    animation-delay: 0.2s;
  }
  
  #chatbot-loader #chatbot-loader-dot3 {
    animation: load 1s infinite;
    animation-delay: 0.4s;
  }
  
  @keyframes load {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .react-chatbot-kit-chat-container {
    position: relative;
    width: 300px;
    border: 1px solid #e7e5e5;
  }
  
  .react-chatbot-kit-chat-inner-container {
    height: 500px;
    background-color: #fff;
    border-radius: 3px;
    border-radius: 5px;
  }
  
  .react-chatbot-kit-chat-header {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: #efefef;
    font-family: Arial;
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    color: #514f4f;
    padding: 12.5px;
    font-weight: bold;
  }
  
  .react-chatbot-kit-chat-input-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
  }
  
  .react-chatbot-kit-chat-message-container {
    padding: 0 17.5px 10px 17.5px;
    overflow: scroll;
    height: 424px;
  }
  
  .react-chatbot-kit-chat-input {
    width: 100%;
    padding: 12.5px;
    border: none;
    font-size: 0.85rem;
    border-top: 1px solid #d8d8d8;
    border-bottom-left-radius: 5px;
  }
  
  .react-chatbot-kit-chat-input-form {
    width: 100%;
    height: 50px;
    display: flex;
  }
  
  .react-chatbot-kit-chat-input::placeholder {
    color: #585858;
  }
  
  .react-chatbot-kit-chat-btn-send {
    background-color: #2898ec;
    width: 80px;
    border: none;
    color: #000000;
    border-bottom-right-radius: 5px;
  }
  
  .react-chatbot-kit-chat-btn-send-icon {
    fill: #ffffff;
    width: 40px;
    position: relative;
    /*bottom: 25px;
    right: 10px;*/
    scale: 0.4;
    
  }
  
  .react-chatbot-kit-error {
    background-color: #fff;
    border-radius: 3px;
    padding: 15px;
  }
  
  .react-chatbot-kit-error-container {
    width: 260px;
  }
  
  .react-chatbot-kit-error-header {
    font-size: 1.3rem;
    color: #1d1d1d;
    margin-bottom: 30px;
  }
  
  .react-chatbot-kit-error-docs {
    display: block;
    margin: 25px auto;
    color: rgb(56, 104, 139);
    padding: 8px;
    border: 1px solid rgb(40, 152, 236);
    width: 130px;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
  }

  .chatbot-outside-container {
    position: fixed;
    bottom: 10px;
    right: 30px;
  }

  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600&display=swap');




.body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.font-inter {
  font-family: 'Inter', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-chatbot-kit-chat-container {
  
  border-radius: 20px;
  width: 360px!important;
  opacity: 1;
  transition: opacity 0.3s ea5e-in-out, transform 0.3s ease-in-out;
  
}

.react-chatbot-kit-chat-inner-container{
  border-radius: 15px!important;
  height: 600px!important;
}

.react-chatbot-kit-user-chat-message{
  background-color: #2E7FF1!important ;
  color: #ffffff!important;
  font-family: 'Inter';
}

.react-chatbot-kit-chat-input{
  font-family: 'Inter';
}

.react-chatbot-kit-user-avatar-container{
  display: none!important;
}

.react-chatbot-kit-chat-btn-send {
  background-color: white!important;
  width: 70px!important;
}

.react-chatbot-kit-chat-btn-send-icon {
  fill: #2253FF!important;
}

.react-chatbot-kit-user-chat-message-arrow{
  border-left: 8px solid #2E7FF1!important ;
}

.react-chatbot-kit-chat-message-container {
  padding: 0 10px 10px 13.5px!important;
  height: 524px!important;
}

.react-chatbot-kit-chat-bot-message-container {
  padding: 0 5px 5px 5px!important;
  width: 290px;
}

.react-chatbot-kit-chat-bot-message {
  width: 245px!important;
}

.react-chatbot-kit-chat-container.active {
  opacity: 1;
  width: 360px;
  transform: translateY(0%);
}

.rounded-button {
  border-radius: 8px;
  width: 150px;
  height: 60px;
  background-color: #FEC400;
  color: white;
  font-weight: bold;
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  display: flex;
  position: fixed;
  right: 20px;
  bottom: 30px;
  z-index: 215361;
  align-items: center;
  justify-content: center;
  transform: translateY(100%); /* Initially move the button below the window */
  cursor: pointer;
}

.chat-with-us-text{
  color: black;
  font-family: Mulish;
  font-size: 1.0rem;
}

.rounded-button.active {
  opacity: 1;
  transform: translateY(0%); /* Move the button back inside the window */
}


.rounded-button:hover {
  background-color: #45a049;
}

.rounded-button:focus {
  outline: none;
}

.chatbot-footer {
  height: 20px;
  font-size: 0.7rem;
  bottom: 0;
  width: 360px;
  text-align: center;
  padding: 10px 0;
  background: #f5f5f5;
  border-top: 1px solid #6f6f6f;
  color: #767676;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.entire-chatbot-container {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  width: 360px;
  transform: translateY(50%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  opacity: 0;
  position: absolute; /* Needed for z-index to work. You might already have this defined somewhere else, or might need to use 'absolute' or 'fixed' instead, depending on your layout. */
  bottom: 30px;
  right: 30px;
  z-index: 9999;
  border-radius: 15px;
}

.entire-chatbot-container.active {
  opacity: 1;
  transform: translateY(0%);
}

.footer-text {
  position: relative;
  font-family: 'Inter', sans-serif;
  bottom: 5px;
}

.jigma-hyperlink{
  font-family: 'Logo font', sans-serif;
  color: #47a1f5;
  font-weight: bolder;
}
.jigma-hyperlink:hover{
  color: #60a5e6df;
}


@media all and (max-width:600px) {
  .react-chatbot-kit-chat-container{
    width: 100vw!important;
    height: 90lvh;
  }
  .rounded-button {
    width: 100px;
    height: 50px;
    right: 5px;
  }
  .chat-with-us-text{
    font-size: 0.7rem;
  }
  .react-chatbot-kit-chat-message-container{
    height: 100%;
  }
  .react-chatbot-kit-chat-inner-container{
    border-radius: 15px!important;
    height: 100%!important;
  }
  .entire-chatbot-container{
    position: absolute;
    width: 100vw;
    height: 90lvh;
    bottom: 40px;
    right: 0px;
  }
  .chatbot-footer{
    width: 100vw;
  }
  .react-chatbot-kit-chat-message-container{
    height: 80vh!important;
  }
}

  
  