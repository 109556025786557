.notificationBar {
    background-color: #FFEB3B;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  }
  
  .notification-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
  
  .notification-message {
    color: #333;
  }
  
  .notification-link {
    color: #f8f8f8;
    margin-left: 10px;
    padding: 4px 6px;
    background-color: #000;
    border-radius: 2px;
    cursor: pointer;
  }
  
  .notification-closeButton {
    background: none;
    border: none;
    color: #333;
    font-size: 20px;
    cursor: pointer;
  }
  