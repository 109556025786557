:root {
    --white-color: #141414;
    --background-color: #ffff;
    --grey-color: #b5b5be;
    --dark-grey-color: #ffffff;
  }

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600&display=swap');

  .side-bar {
    width: 11.0rem;
    height: 100vh;
    border: #ededed 0.2px solid;
    padding: 2.1rem 1.2rem;
    background-color: #f9f9f9;
    position: relative;
    z-index: 11;
    transition: all 0.5s ease;
    right: 200px;left: 0;bottom: 0;
  }

  .side-bar-mobile {
    width: 100%;
    height: 100vh;
    border: #ededed 0.2px solid;
    padding: 2.1rem 1.2rem;
    background-color: #f9f9f9;
    position: relative;
    z-index: 11;
    transition: all 0.5s ease;
    right: 200px;left: 0;bottom: 0;
  }
 
  .logo-name-wrapper {
    position: relative;
    margin-bottom: 2.1rem;
    display: flex;
    font-size: 1.2rem;
    transition: all 0.5s ease;
  }
  .logo-name {
    display: flex;
    align-items: center;
    transition: all 0.5s ease;
  }
  .logo-name__name {
    margin-left: 0.9rem;
    font-size: 1.5rem;
    height: 3.5rem;
    white-space: nowrap;
    transition: all 0.5s ease;
  }
  .logo-name__button {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 1.8rem;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .logo-name__icon {
    font-size: 1.8rem;
    color: #141414;
  }

  .logo-name__icon:hover {
    font-size: 1.8rem;
    color: #1414147a;
  }
  .message {
    background-color: var(--dark-grey-color);
    border: #cfcece 1px solid;
    display: flex;
    align-items: center;
    padding: 1rem 0 1rem 1.2rem;
    border-radius: 0.4rem;
    margin-bottom: 1.7rem;
    cursor: pointer;
    position: relative;
  }
  .message-icon {
    font-size: 1.5rem;
    color: #b5b5be;
    transform: translateX(3rem);
    transition: all 0.5s ease;
  }
  .message-text {
    margin-left: 1.1rem;
    color: #292932;
    font-size: 0.9rem;
    white-space: nowrap;
    transform: translateX(3rem);
    transition: all 0.5s ease;
  }

  .features-list {
    padding-bottom: 1rem;
    padding-left: 0px;
    border-bottom: 1px solid #292932;
    list-style: none;
  }
  .features-item {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0.5rem;
    margin-bottom: 0.5rem;
    color: var(--grey-color);
    cursor: pointer;
    transition: 0.5s ease;
  }
  .features-item::before {
    transition: 0.5s ease;
    content: '';
    position: absolute;
    transform: translate(-3rem);
    left: -1.2rem;
    height: 100%;
    border-left: 3px solid var(--white-color);
    border-radius: 2px;
    opacity: 0;
    visibility: hidden;
  }
  .features-item:hover {
    color: var(--white-color);
    transition: 0.5s ease;
  }
  .features-item:hover::before {
    opacity: 1;
    visibility: unset;
  }
  .features-item-box {
    display: inline-flex;
  }
  .features-item-icon {
    font-size: 1.5rem;
  }
  
  .features-item-text {
    margin-left: 0.9rem;
    font-size: 0.9rem;
    padding-top: 2px;
    font-family: 'Archivo', sans-serif;
    transition: opacity 0.6s ease;
    color: #292932;
    opacity: 1;
  }
  .inbox-number {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: #1e75ff;
    padding: 0.5rem 0.9rem;
    border-radius: 0.4rem;
    color: var(--white-color);
    opacity: 1;
    transition: 0.6s linear;
  }
  .inbox-icon {
    position: relative;
  }
  .inbox-icon .status {
    position: absolute;
    top: 0;
    right: -1px;
    background-color: #1e75ff;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    border: 2px solid var(--white-color);
    opacity: 0;
    transition: 0.6s linear;
  }
  .category-list {
    padding-top: 1.0rem;
    padding-bottom: 0.9rem;
    padding-left: 0px;
    color: var(--grey-color);
    list-style: none;
    font-size: 1.2rem;
    font-weight: 500;
    border-bottom: 1px solid #292932;
    align-items: start;
    align-content: start;
    justify-content: start;
  }
  .category-header {
    font-size: 1.2rem;
    text-transform: uppercase;
    line-height: 1.8rem;
    color: var(--white-color);
    margin-bottom: 1.3rem;
    white-space: nowrap;
  }
  .category-item {
    position: relative;
    padding: 0.9rem 1.1rem;
    line-height: 1.8rem;
    left: 0;
    cursor: pointer;
    white-space: nowrap;
  }
  .category-item-status {
    height: 0.8rem;
    width: 0.8rem;
    border-radius: 50%;
    border: none;
    background-color: white;
    display: inline-block;
  }
  .category-item-text {
    margin-left: 1rem;
    transition: opacity 0.6s ease;
    opacity: 1;
  }
  .category-item-number {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  .chat-list {
    padding: 2.1rem 0;
    font-size: 1.2rem;
  }
  .chat-header {
    text-transform: uppercase;
    line-height: 1.8rem;
    color: var(--white-color);
    margin-bottom: 1.3rem;
    white-space: nowrap;
  }
  .chat-new-btn {
    color: #1e75ff;
    background-color: transparent;
    outline: none;
    border: none;
    line-height: 1.8rem;
    display: flex;
    align-items: center;
    padding-bottom: 2.1rem;
    padding-left: 0.1rem;
    cursor: pointer;
    position: relative;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
  }
  .chat-new-btn-text {
    white-space: nowrap;
    transition: opacity 0.6s ease;
    opacity: 1;
  }
  .chat-icon {
    font-size: 2.1rem;
    margin-right: 1.2rem;
  }
  .chat-item {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 2.1rem;
    color: var(--grey-color);
    cursor: pointer;
  }
  .chat-item-avatar-wrapper {
    position: relative;
  }
  .chat-item-avatar-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    right: -3px;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    background-color: #79d861;
    opacity: 0;
    transition: 0.6s linear;
  }
  .chat-item-avatar-wrapper.has-message::before {
    background-color: #1e75ff;
    border: 2px solid var(--white-color);
    height: 6px;
    width: 6px;
  }
  .chat-item-avatar {
    object-fit: cover;
    object-position: top;
    height: 2.1rem;
    width: 2.1rem;
    border-radius: 50%;
    border: 1px solid var(--white-color);
  }
  /* background color in inline style */
  .chat-item-status {
    height: 0.8rem;
    width: 0.8rem;
    border-radius: 50%;
    border: none;
    background-color: white;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-right: 5px;
    opacity: 1;
    transition: 0.6s linear;
  }
  .chat-item-number {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: #1e75ff;
    padding: 0.5rem 0.9rem;
    border-radius: 0.4rem;
    color: var(--white-color);
    opacity: 1;
    transition: 0.6s linear;
  }
  .chat-item-name {
    white-space: nowrap;
    transition: opacity 0.6s ease;
    opacity: 1;
    margin-left: 1.4rem;
  }
  .tooltip {
    position: absolute;
    top: -2.1rem;
    left: 3.4rem;
    color: #141414;
    background-color: white;
    border-radius: 0.4rem;
    padding: 0.5rem 0.9rem;
    font-size: 0.8rem;
    transition: 0s;
    opacity: 0;
    display: none;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  .side-bar.collapse2 .message:hover .tooltip,
  .features-item:hover .tooltip,
  .category-item:hover .tooltip,
  .chat-new-btn:hover .tooltip {
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5s ease;
    opacity: 1;
  }
  .side-bar.collapse2 {
    width: 5.4rem;
  }
  .side-bar.collapse2 .tooltip {
    display: block;
  }
  .side-bar.collapse2 .logo-name,
  .side-bar.collapse2 .category-header,
  .side-bar.collapse2 .chat-header,
  .side-bar.collapse2 .chat-new-btn-text,
  .side-bar.collapse2 .features-item-text,
  .side-bar.collapse2 .inbox-number,
  .side-bar.collapse2 .category-item-text,
  .side-bar.collapse2 .category-item-number,
  .side-bar.collapse2 .message-text,
  .side-bar.collapse2 .chat-item-name,
  .side-bar.collapse2 .chat-item-number,
  .side-bar.collapse2 .chat-item-status {
    opacity: 0;
    pointer-events: none;
  }
  .side-bar.collapse2 .message-text {
    opacity: 0;
    pointer-events: none;
    transform: translateX(0);
  }
  .side-bar.collapse2 .message-icon {
    transform: translateX(0);
  }
  .side-bar.collapse2 .logo-name__button {
    right: 0.2rem;
  }
  .side-bar.collapse2 .inbox-icon .status,
  .side-bar.collapse2 .chat-item-avatar-wrapper::before {
    opacity: 1;
  }

  @media all and (max-width:700px) {
    .side-bar .message:hover .tooltip,
  .features-item:hover .tooltip,
  .category-item:hover .tooltip,
  .chat-new-btn:hover .tooltip {
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5s ease;
    opacity: 1;
  }
  .side-bar {
    width: 3.5rem;
  }
  .side-bar .tooltip {
    display: block;
  }
  .side-bar .logo-name,
  .side-bar .category-header,
  .side-bar .chat-header,
  .side-bar .chat-new-btn-text,
  .side-bar .features-item-text,
  .side-bar .inbox-number,
  .side-bar .category-item-text,
  .side-bar .category-item-number,
  .side-bar .message-text,
  .side-bar .chat-item-name,
  .side-bar .chat-item-number,
  .side-bar .chat-item-status {
    opacity: 0;
    pointer-events: none;
  }
  .side-bar .message-text {
    opacity: 0;
    pointer-events: none;
    transform: translateX(0);
  }
  .side-bar .message-icon {
    transform: translateX(0);
  }
  .side-bar .logo-name__button {
    right: 0.2rem;
  }
  .side-bar .inbox-icon .status,
  .side-bar .chat-item-avatar-wrapper::before {
    opacity: 1;
  }
    .side-bar.collapse2{
      display: none;
      width: 4rem;
    }
    .side-bar-mobile.collapse2{
      display: none;
      width: 4rem;
    }
    .features-list {
      margin-left: -15px;
    }
  }