.login{
    background-color: black;
    justify-content: center;
    height: 100%;
}


.title_login{
    background: linear-gradient(to right, #0d6efd, #1cc7d0, #00c2a8, #7fcdff, #616161);
    background-clip: content-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: 100%;
    padding: 25px;
    margin-bottom: 10px;
    font-family: "Logo font", sans-serif;
    font-size: 4.0rem;
    font-weight: bold;
}

.footer{
    background-color: black;
    height: 14.4rem;
    display: flex;
}

.button-signup{
    background-color: black;
    color: white;
    text-decoration: none;
    font-size: 1rem;
    padding: 0.6rem 4rem;
    text-align: center;
}
