.text_left_model{
    margin-top: 4.5rem;
    text-align: center;
}

.right_text_model{
    position: absolute;
    right: 2rem;
    bottom: 4rem;

}

.bottom_part_model{
    text-align: center;
}

.button_right_model{
    
    background-color: blue;
    border-radius: 100%;
    display: block;
    height: 3.5rem;
    width:  3.5rem;
    margin-left: 2rem;
    box-shadow: 1px 1px 1px black;

}

.svg_plus_model{
    position: relative;
    right: 1.1rem;
    bottom: 1.1rem;
}