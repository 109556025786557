.rounded_image_model{
    width:4.0rem;
    height:4.0rem;
    border-radius:150px;
}
.item_model{
    border-bottom: solid 2px;
    border-color: rgb(224, 224, 224);
    margin: 10px 0px;
    
}

.image_card_model{
   width: 10rem;
}