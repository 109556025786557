.export-chat-header {
    background-color: #90a5ea ;
    padding: 0.5rem;
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-chat {
    font-size: 1.5rem;
    position: relative;
    align-self: flex-end;
    cursor: pointer;
}

.chatbox1 {
    box-sizing: border-box;
    flex: 1;
    width: 100vw;
    background-color: white;
    position: relative;
    display: flex;
    outline: None;
    height: 100vh;
    

}

.box-chat-container {
    border: #f3f3f3 0.1px solid;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: none;
}

.chat-window1 {
   
    height: 100vh; /* set height to 80% of viewport height */
    max-height: 100vh;
    
}

.chat-input-holder1 {
    padding-left: 0.6rem;
    position: absolute;
    bottom: 0;
    outline: None;
    width: 95%;
}

.chat-input-textarea1 {
    /*background-color: #40414f;*/
    background-color: rgba(255, 255, 255, 0.95);
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    max-height: 150px;
    padding: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    border: None;
    margin: 5px;
    padding-right: 10%;
    outline: None;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
    color: black;
}