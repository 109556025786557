.signup{
    background-color: black;
    
}


.title_signup{
    background: linear-gradient(to right, #0d6efd, #1cc7d0, #00c2a8, #7fcdff, #616161);
    background-clip: content-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: 100%;
    padding: 25px;
    margin-bottom: 10px;
    font-family: "Logo font", sans-serif;
    font-size: 4.0rem;
    font-weight: bold;
}
.footer{
    background-color: black;
    height: 17rem;
    display: flex;
}