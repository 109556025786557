
.pricing-link-footer {
    margin-right: 20px; /* Add some spacing between the link and the button */
    color: #a1a1a1; /* Set the text color */
    text-decoration: none; /* Remove underline */
    margin-bottom: 0px;
    cursor: pointer; /* Cursor style */
  }

  .pricing-link-footer:hover{
    color: #0056b3;
  }

.contact-direct-email{
  position: relative;
  bottom: -200px;
}

@media (max-width: 768px) {
  .contact-direct-email{
    display: none;
  }
}