.content-static-analytics {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.content-dynamic-analytics {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.scorecard-container {
  display: flex;
  justify-content: space-between; /* space out the cards */
  flex-wrap: wrap; /* allow the cards to wrap to a new line */
  margin-bottom: 1rem;
  color: rgb(73, 73, 73);
}

.scorecard {
  flex: 1 0 20%; /* grow, don't shrink, and start at a width of 20% */
  background: #291c3c;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  margin: 0.5rem; /* add a bit of margin around each card */
}

.scorecard h6{
  color: #b9b9b9;
}

.scorecard p{
  font-size: 2rem;
}

.pdf-button-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.pdf-button {
  background-color: #f44336; /* Change color as needed */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

.pdf-button:hover {
  background-color: #d32f2f; /* Change hover color as needed */
}

.date-selectors {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  padding: 10px 0;
  color: #f1f1f1; /* Updated text color to match theme */
}

.date-selectors label {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}

.date-selectors input {
  outline: none;
  border: 1px solid #291c3c; /* Border color to match theme */
  border-radius: 4px;
  padding: 5px 10px; /* Increased padding slightly for better appearance */
  font-size: 0.9rem;
  transition: border-color 0.3s ease; /* Transition for smooth color change on hover */
  color: #291c3c; /* Text color to match theme */
}

.date-selectors input:hover {
  border-color: #634e75; /* Slightly lighter color on hover for a subtle effect */
}

.date-selectors input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  color: #291c3c; /* Icon color to match theme */
  font-size: 1.2em;
}




