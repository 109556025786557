.box {
    border: 0px none;
    height: 100vh;
    width: 90vw;
    scale: 0.7;
    display: flex;
    position: relative;
    top: -90px;
  }
  
  .box .group {
    left: 0px;
    position: relative;
    top: 0px;
    
  }
  
  .box .overlap {
    
    position: relative;
   
  }
  
  .box .plans {
    left: 0;
    position: absolute;
    top: 45px;
  }
  
  .box .free-plan {
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 16px 72px #1500311a;
    height: 460px;
    left: 0;
    position: absolute;
    top: 0;
    width: 628px;
  }
  
  .box .div {
    height: 360px;
    left: 48px;
    position: relative;
    top: 48px;
    width: 489px;
  }
  
  .box .button {
    align-items: center;
    background: linear-gradient(180deg, rgb(112.8, 34.07, 255) 0%, rgb(134, 67, 255) 100%);
    border: 1px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgb(171.01, 124.58, 255), rgba(255, 255, 255, 0.1)) 1;
    border-radius: 6px;
    display: flex;
    gap: 10px;
    justify-content: center;
    left: 0;
    padding: 20px 24px;
    position: absolute;
    top: 296px;
    width: 257px;
  }
  
  .box .text-wrapper {
    color: #ffffff;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 23.8px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .box .frame {
    align-items: center;
    background-color: #e6ffef;
    border-radius: 48px;
    display: inline-flex;
    gap: 12px;
    left: 0;
    overflow: hidden;
    padding: 12px 20px;
    position: absolute;
    top: 0;
  }
  
  .box .img {
    height: 24px;
    position: relative;
    width: 24px;
  }
  
  .box .text-wrapper-2 {
    color: #26a550;
    font-family: "Prompt-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -6px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .box .element-incoming-messages {
    height: 53px;
    left: 2px;
    position: absolute;
    top: 156px;
    width: 300px;
  }
  
  .box .text-wrapper-3 {
    color: #0d071c;
    font-family: "Inter-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 30px;
    position: absolute;
    top: 222px;
    white-space: nowrap;
  }
  
  .box .you-don-t-need-to-be {
    color: #3d3949;
    font-family: "Inter-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 30px;
    position: absolute;
    top: 66px;
    width: 430px;
  }
  
  .box .overlap-group {
    height: 97px;
    left: 291px;
    position: absolute;
    top: 231px;
    width: 190px;
  }
  
  .box .arrow {
    height: 90px;
    left: 0;
    position: absolute;
    top: 0;
    width: 124px;
  }
  
  .box .p {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(180deg, rgb(34.07, 82.67, 255) 0%, rgb(67, 209.88, 255) 100%);
    background-clip: text;
    color: transparent;
    font-family: "Kalam-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 93px;
    letter-spacing: 0;
    line-height: 18.5px;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 32px;
    transform: rotate(-15deg);
    width: 92px;
  }
  
  .box .monthly-plan {
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 16px 72px #1500311a;
    height: 748px;
    left: 652px;
    position: absolute;
    top: 0;
    width: 628px;
  }
  
  .box .group-2 {
    height: 648px;
    left: 5px;
    position: relative;
    top: 48px;
    width: 624px;
  }
  
  .box .frame-2 {
    align-items: center;
    background-color: #d8f8ff;
    border-radius: 48px;
    display: inline-flex;
    gap: 12px;
    left: 43px;
    overflow: hidden;
    padding: 12px 20px;
    position: absolute;
    top: 0;
  }
  
  .box .text-wrapper-4 {
    color: #0884de;
    font-family: "Prompt-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -6px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .box .element-estimated-USD {
    height: 58px;
    left: 46px;
    position: absolute;
    font-family: 'Roboto', Arial, sans-serif;
    color: rgb(134, 67, 255);
    font-weight: 900;
    font-size: 3.5rem;
    display: flex;
    top: 152px;
    width: 332px;
  }

  .price-currency{
    color: lightgray;
    font-size: 2.0rem;
    display: inline-flex;
    align-self: flex-end;
  }

  .pay-button{
    position: absolute;
    bottom: 0;
    background-color: rgb(134, 67, 255)!important;
    margin: 0!important;
    width: 60%;
    font-size: 1.5rem;
  }

  .pay-button-container{
    position: relative;
    bottom: -30px;
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .box .text-wrapper-5 {
    color: #0d071c;
    font-family: "Inter-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    left: 43px;
    letter-spacing: 0;
    line-height: 30px;
    position: absolute;
    top: 222px;
    white-space: nowrap;
  }
  
  .box .you-don-t-need-to-be-2 {
    color: #3d3949;
    font-family: "Inter-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 43px;
    letter-spacing: 0;
    line-height: 30px;
    position: absolute;
    top: 66px;
    width: 430px;
  }
  
  .box .frame-3 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 12px;
    left: 43px;
    position: absolute;
    top: 276px;
  }
  
  .box .group-3 {
    height: 16px;
    margin-right: -12px;
    position: relative;
    width: 544px;
  }
  
  .box .text {
    color: #98979a;
    font-family: "Inter-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 16px;
    left: 0;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: nowrap;
  }
  
  .box .text-2 {
    color: #98979a;
    font-family: "Inter-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 16px;
    left: 90px;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: nowrap;
  }
  
  .box .text-3 {
    color: #98979a;
    font-family: "Inter-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 16px;
    left: 190px;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: nowrap;
  }
  
  .box .text-4 {
    color: #98979a;
    font-family: "Inter-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 16px;
    left: 292px;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: nowrap;
  }
  
  .box .text-5 {
    color: #98979a;
    font-family: "Inter-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 16px;
    left: 394px;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: nowrap;
  }
  
  .box .text-6 {
    color: #98979a;
    font-family: "Inter-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 16px;
    left: 495px;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: nowrap;
  }
  
  .box .slider {
    border-radius: 8px;
    height: 56px;
    position: relative;
    width: 532px;
  }
  
  .box .progress-wrapper {
    background-color: #eaecf0;
    border-radius: 4px;
    height: 8px;
    position: relative;
    top: 8px;
  }
  
  .box .progress {
    background: linear-gradient(180deg, rgb(112.8, 34.07, 255) 0%, rgb(134, 67, 255) 100%);
    border-radius: 4px;
    height: 8px;
    position: relative;
    width: 160px;
  }
  
  .box .control-handle {
    background-color: #ffffff;
    border: 1.5px solid;
    border-color: #7e56d8;
    border-radius: 12px;
    height: 24px;
    left: 0;
    position: absolute;
    top: -8px;
    width: 24px;
  }
  
  .box .handle {
    background-color: #ffffff;
    border: 1.5px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgb(112.8, 34.07, 255), rgb(134, 67, 255)) 1;
    border-radius: 12px;
    box-shadow: 0px 2px 4px -2px #1018280f, 0px 4px 8px -2px #1018281a;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
  }
  
  .box .text-7 {
    color: #0d071c;
    font-family: "Inter-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    height: 24px;
    left: 4px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    text-align: center;
    top: 30px;
    white-space: nowrap;
  }
  
  .box .control-handle-2 {
    background-color: #ffffff;
    border: 1.5px solid;
    border-color: #7e56d8;
    border-radius: 12px;
    height: 24px;
    left: 148px;
    position: absolute;
    top: -8px;
    width: 24px;
  }
  
  .box .text-8 {
    color: #0d071c;
    font-family: "Inter-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    height: 24px;
    left: -2px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    text-align: center;
    top: 30px;
    white-space: nowrap;
  }
  
  .box .frame-4 {
    align-items: flex-start;
    display: inline-flex;
    width: 80%;
    left: 43px;
    position: absolute;
    top: 412px;
  }
  
  .box .text-wrapper-6 {
    color: #3d3949;
    font-family: "Inter-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .box .text-9 {
    color: #0d071c;
    font-family: "Inter-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    margin-left: auto;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }
  
  .box .frame-5 {
    align-items: flex-start;
    display: inline-flex;
    gap: 208px;
    left: 43px;
    position: absolute;
    top: 480px;
  }
  
  .box .line {
    height: 1px;
    left: 42px;
    object-fit: cover;
    position: absolute;
    top: 456px;
    width: 533px;
  }
  
  .box .line-2 {
    height: 2px;
    left: -1px;
    position: absolute;
    top: 543px;
    width: 620px;
  }
  
  .box .frame-6 {
    align-items: center;
    display: inline-flex;
    gap: 24px;
    left: 43px;
    position: absolute;
    top: 576px;
  }
  
  .box .text-wrapper-7 {
    color: #3d3949;
    font-family: "Inter-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 15px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .box .frame-7 {
    align-items: center;
    display: inline-flex;
    gap: 24px;
    left: 43px;
    position: absolute;
    top: 614px;
  }
  
  .box .enterprise-plan {
    height: 264px;
    left: 0;
    position: absolute;
    top: 484px;
    width: 634px;
  }
  
  .box .overlap-2 {
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 16px 72px #1500311a;
    height: 264px;
    position: relative;
    width: 628px;
  }
  
  .box .text-wrapper-8 {
    color: #252033;
    font-family: "Prompt-SemiBold", Helvetica;
    font-size: 28px;
    font-weight: 600;
    left: 48px;
    letter-spacing: 0;
    line-height: 36.4px;
    position: absolute;
    top: 40px;
    white-space: nowrap;
  }
  
  .box .div-wrapper {
    align-items: center;
    background: linear-gradient(180deg, rgb(34.07, 82.67, 255) 0%, rgb(67, 209.88, 255) 100%);
    border: 1px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgb(171.01, 124.58, 255), rgba(255, 255, 255, 0.1)) 1;
    border-radius: 6px;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    left: 48px;
    padding: 16px 24px;
    position: absolute;
    top: 174px;
  }
  
  .box .text-wrapper-9 {
    color: #ffffff;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .box .you-don-t-need-to-be-3 {
    color: #3d3949;
    font-family: "Inter-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 48px;
    letter-spacing: 0;
    line-height: 30px;
    position: absolute;
    top: 92px;
    width: 494px;
  }
  
  .box .text-wrapper-10 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(180deg, rgb(34.07, 82.67, 255) 0%, rgb(67, 209.88, 255) 100%);
    background-clip: text;
    color: transparent;
    font-family: "Kalam-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 363px;
    letter-spacing: 0;
    line-height: 18.5px;
    position: absolute;
    text-align: center;
    text-fill-color: transparent;
    top: 168px;
    transform: rotate(15deg);
    width: 77px;
  }
  
  .box .vector {
    height: 60px;
    left: 218px;
    position: absolute;
    top: 169px;
    width: 143px;
  }
  
  .box .overlap-wrapper {
    height: 160px;
    left: 1162px;
    position: absolute;
    top: 0;
    width: 162px;
  }
  
  .box .overlap-3 {
    height: 160px;
    position: relative;
    width: 165px;
  }
  
  .box .ellipse {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgb(0, 231, 178) 0%,
      rgb(3, 230, 177) 35.24%,
      rgb(11, 225, 175) 57.53%,
      rgb(26, 218, 170) 76.22%,
      rgb(47, 207, 164) 92.83%,
      rgb(58, 201, 160) 100%
    );
    border-radius: 80px;
    height: 160px;
    left: 0;
    position: absolute;
    top: 0;
    width: 160px;
  }
  
  .box .ellipse-2 {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgb(255, 199, 56) 0%,
      rgb(255, 198, 54) 48.73%,
      rgb(255, 194, 47) 67.46%,
      rgb(255, 187, 35) 81.03%,
      rgb(255, 177, 18) 92.03%,
      rgb(255, 167, 0) 100%
    );
    border-radius: 75.43px;
    height: 151px;
    left: 5px;
    position: absolute;
    top: 5px;
    width: 151px;
  }
  
  .box .ellipse-3 {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgb(255, 73, 116) 0%,
      rgb(254, 71, 114) 40.73%,
      rgb(251, 63, 109) 59.63%,
      rgb(247, 51, 99) 74.03%,
      rgb(240, 32, 85) 86.15%,
      rgb(231, 9, 68) 96.73%,
      rgb(228, 0, 61) 100%
    );
    border-radius: 51.43px;
    height: 103px;
    left: 29px;
    position: absolute;
    top: 29px;
    width: 103px;
  }
  
  .box .group-4 {
    height: 12px;
    left: 56px;
    position: absolute;
    top: 116px;
    width: 48px;
  }
  
  .box .star {
    height: 6px;
    left: 21px;
    position: absolute;
    top: 5px;
    width: 7px;
  }
  
  .box .star-2 {
    height: 6px;
    left: 10px;
    position: absolute;
    top: 4px;
    width: 7px;
  }
  
  .box .star-3 {
    height: 6px;
    left: 0;
    position: absolute;
    top: 0;
    width: 7px;
  }
  
  .box .star-4 {
    height: 6px;
    left: 31px;
    position: absolute;
    top: 4px;
    width: 7px;
  }
  
  .box .star-5 {
    height: 6px;
    left: 41px;
    position: absolute;
    top: 0;
    width: 7px;
  }
  
  .box .group-5 {
    height: 12px;
    left: 56px;
    position: absolute;
    top: 32px;
    width: 48px;
  }
  
  .box .star-6 {
    height: 6px;
    left: 21px;
    position: absolute;
    top: 1px;
    width: 7px;
  }
  
  .box .star-7 {
    height: 6px;
    left: 10px;
    position: absolute;
    top: 2px;
    width: 7px;
  }
  
  .box .star-8 {
    height: 6px;
    left: 0;
    position: absolute;
    top: 6px;
    width: 7px;
  }
  
  .box .star-9 {
    height: 6px;
    left: 31px;
    position: absolute;
    top: 2px;
    width: 7px;
  }
  
  .box .star-10 {
    height: 6px;
    left: 41px;
    position: absolute;
    top: 6px;
    width: 7px;
  }
  
  .box .group-6 {
    height: 18px;
    left: 7px;
    position: absolute;
    top: 71px;
    width: 146px;
  }
  
  .box .star-11 {
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    width: 17px;
  }
  
  .box .star-12 {
    height: 16px;
    left: 128px;
    position: absolute;
    top: 0;
    width: 17px;
  }
  
  .box .element-DAYS {
    color: #f9f9f9;
    font-family: "Inter-ExtraBold", Helvetica;
    font-size: 36px;
    font-weight: 400;
    left: 55px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 48px;
  }
  
  .box .span {
    font-weight: 800;
  }
  
  .box .text-wrapper-11 {
    font-family: "Inter-Bold", Helvetica;
    font-size: 18px;
    font-weight: 700;
  }
  
  .box .overlap-group-wrapper {
    height: 52px;
    left: 14px;
    position: absolute;
    top: 6px;
    width: 151px;
  }
  
  .box .overlap-4 {
    height: 52px;
    position: relative;
    width: 133px;
  }
  
  .box .overlap-5 {
    height: 52px;
    left: 0;
    position: absolute;
    top: 0;
    width: 62px;
  }
  
  .box .overlap-group-2 {
    height: 48px;
    left: 0;
    position: absolute;
    top: 4px;
    width: 51px;
  }
  
  .box .text-wrapper-12 {
    color: #2d3748;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18.3px;
    font-weight: 600;
    left: 5px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 24px;
    transform: rotate(-55.78deg);
  }
  
  .box .text-wrapper-13 {
    color: #2d3748;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18.3px;
    font-weight: 600;
    left: 19px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 11px;
    transform: rotate(-39.53deg);
  }
  
  .box .text-wrapper-14 {
    color: #2d3748;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18.3px;
    font-weight: 600;
    left: 33px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 2px;
    transform: rotate(-24.65deg);
  }
  
  .box .text-wrapper-15 {
    color: #2d3748;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18.3px;
    font-weight: 600;
    left: 48px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 1px;
    transform: rotate(-11.29deg);
  }
  
  .box .text-wrapper-16 {
    color: #2d3748;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18.3px;
    font-weight: 600;
    left: 62px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
    transform: rotate(1.79deg);
  }
  
  .box .overlap-6 {
    height: 51px;
    left: 75px;
    position: absolute;
    top: 1px;
    width: 59px;
  }
  
  .box .text-wrapper-17 {
    color: #2d3748;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18.3px;
    font-weight: 600;
    left: 3px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 1px;
    transform: rotate(15.43deg);
  }
  
  .box .overlap-7 {
    height: 46px;
    left: 12px;
    position: absolute;
    top: 5px;
    width: 47px;
  }
  
  .box .text-wrapper-18 {
    color: #2d3748;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18.3px;
    font-weight: 600;
    left: 4px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 2px;
    transform: rotate(29.2deg);
  }
  
  .box .text-wrapper-19 {
    color: #2d3748;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18.3px;
    font-weight: 600;
    left: 17px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 12px;
    transform: rotate(43.66deg);
  }
  
  .box .text-wrapper-20 {
    color: #2d3748;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18.3px;
    font-weight: 600;
    left: 28px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 24px;
    transform: rotate(57.71deg);
  }
  
  .box .group-7 {
    height: 44px;
    left: 20px;
    position: absolute;
    top: 111px;
    width: 138px;
  }
  
  .box .overlap-8 {
    height: 44px;
    position: relative;
    width: 120px;
  }
  
  .box .overlap-9 {
    height: 44px;
    left: 0;
    position: absolute;
    top: 0;
    width: 57px;
  }
  
  .box .text-wrapper-21 {
    color: #2d3748;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18.3px;
    font-weight: 600;
    left: 6px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 2px;
    transform: rotate(45.92deg);
  }
  
  .box .text-wrapper-22 {
    color: #2d3748;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18.3px;
    font-weight: 600;
    left: 17px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 11px;
    transform: rotate(33.5deg);
  }
  
  .box .text-wrapper-23 {
    color: #2d3748;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18.3px;
    font-weight: 600;
    left: 29px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 17px;
    transform: rotate(21.32deg);
  }
  
  .box .text-wrapper-24 {
    color: #2d3748;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18.3px;
    font-weight: 600;
    left: 43px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 21px;
    transform: rotate(9.72deg);
  }
  
  .box .overlap-10 {
    height: 44px;
    left: 55px;
    position: absolute;
    top: 0;
    width: 65px;
  }
  
  .box .text-wrapper-25 {
    color: #2d3748;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18.3px;
    font-weight: 600;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 22px;
    transform: rotate(-1.87deg);
  }
  
  .box .text-wrapper-26 {
    color: #2d3748;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18.3px;
    font-weight: 600;
    left: 14px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 20px;
    transform: rotate(-14.17deg);
  }
  
  .box .text-wrapper-27 {
    color: #2d3748;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18.3px;
    font-weight: 600;
    left: 27px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 15px;
    transform: rotate(-26deg);
  }
  
  .box .text-wrapper-28 {
    color: #2d3748;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18.3px;
    font-weight: 600;
    left: 38px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 9px;
    transform: rotate(-36.78deg);
  }
  
  .box .text-wrapper-29 {
    color: #2d3748;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18.3px;
    font-weight: 600;
    left: 47px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 1px;
    transform: rotate(-47.09deg);
  }
  
  @media all and (max-width:800px) {
    .free-plan{
      display:none
    }
    .enterprise-plan{
      display: none;
    }
  }