.content-contact-us{
    margin-top: 80px;
    align-self: start;
    margin-left: -50px;
}

.signin-login{
    display: flex;
    flex-direction: row;
    width: 100%!important;
}

.left-content{
    display: flex;
    width: 50vw;
    height: 100vh;
}

.left-container{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10% 10% 20% 25%;
    gap: 24px;
    scale: 0.9;
}



.start-content-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 60px;
}

.start-top-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 48px;
}

.start-top-content .jigma-text{
    color: #FFF;
    font-family: Prompt;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 28px */
}

.start-top-content .io-text{
    color: #8643FF;
    font-family: Prompt;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
}

.start-title-description{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 25px;
    gap: 15px;
}

.start-title-description .start-title{
    color: #FFF;
    font-family: Prompt;
    font-size: 40px;
    width: 90%;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 44px */
}

.start-title-description .description{
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
}

.features-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: -10px;
    gap: 20px;
}

.features-content{
    display: flex;
    align-items: flex-start;
    gap: 16px;
}

.start-feature-text{
    color: #FFF;
    font-family: Prompt;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 26px */
}


/* Bottom Content */

.card-rectangle{
    width: 560px;
    height: 288px;
    flex-shrink: 0;
    position: absolute;
    background: url(../../Assets/ContactUs/card_rectangle.png), lightgray 50% / cover no-repeat;
    z-index: -1;
}

.start-bottom-container{
    display: flex;
    flex-direction: column;
    margin-top: -15px;
    gap: 24px;
}

.start-bottom-content{
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    gap: 24px;
    flex-shrink: 0;
    border-radius: 16px;
    background: linear-gradient(180deg, rgba(217, 217, 217, 0.05) 0%, rgba(217, 217, 217, 0.00) 100%);
}

.start-bottom-quota{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.start-bottom-quota .quota-text{
    color: #FFF;
    font-family: Inter;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
}

.start-bottom-profile{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.start-bottom-profile-info{
    display: flex;
    align-items: flex-start;
    gap: 6px;
}

.start-bottom-profile-info .name{
    color: #FFF;
    font-family: Prompt;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 18px */
    text-transform: capitalize;
}

.start-bottom-profile-info .profile-job{
    color: #FFF;
    font-family: Prompt;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    text-transform: capitalize;
}

/* Right Content */

.right-container{
    width: 50vw;
    height: 100vh;
}

.right-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10% 20% 10% 15%;
    scale: 0.9;
}

.signup-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.signup-title-description{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    gap: 12px;
}

.signup-title1{
    color: #FFF;
    font-family: Prompt;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 44px */
}

.signup-description1{
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
}

.signup-buttons{
    display: flex;
    align-items: flex-start;
    width: 110%;
    gap: 20px;
}

.signup-google,.signup-apple{
    display: flex;
    padding: 12px 20px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #FFF;
}

.or-text{
    color: #D0D5DD;
    font-family: Inter;
    font-size: 14px;
    margin-top: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
}
  

.info-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 32px;
}

.signup-inputs{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    width: 100%;
    gap: 15px;
}

.input-name,.input-email,.input-password{
    display: flex;
    padding: 14px 18px;
    width: 100%;
    height: 45px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #FFF;
}

.input-message{
    padding: 14px;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    font-family: Inter;
}

.must-be-at-least{
    color: var(--gray-600, #FFF);
    font-family: Inter;
    font-size: 12px;
    margin-top: 4px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
}

.checkbox-labels{
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    margin-left: 10px;
    font-weight: 400;
    margin-top: 15px;
    line-height: 100%; /* 14px */
}

.ai-assistant-can-contact{
    color: #FFF;
text-align: center;
margin-left: 4em;
/* Heading/h1 */
font-family: 'Prompt', sans-serif;
font-size: 64px;
font-style: normal;
font-weight: 600;
line-height: 100%; /* 64px */
}

.create-account-button{
    display: flex;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 20px 24px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border: none;
    border-radius: 8px;
    background: var(--1, linear-gradient(90deg, #7122FF 0%, #8643FF 100%));
    cursor: pointer;
}

.create-account-button-text{
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
}

.have-an-account-text{
    color: var(--text-default-100, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
}

.signin-to-your-account{
    color: #8643FF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-left: 5px;
    line-height: 100%; /* 16px */
    cursor: pointer;
}

@media (max-width: 1200px) {
    .start-bottom-container{
        display: none;
    }
}

@media (max-width: 780px) {
    .left-content{
        display: none;
    }

    .ai-assistant-can-contact{
        font-size: 3em;
        margin-left: 50px;
    }

    .right-content{
        width: 90vw;
    }

    .background-card{
        display: none;
    }
}