.edit_profile_card{
    border-radius: 15px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 50px -17px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 50px -17px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 50px -17px rgba(0,0,0,0.75);
}

.content-edit-profile {
    position: relative;
    left: 25%;
    width: 40vw;
}

.font-edit-profile {
    font-family: 'Inter', sans-serif;
    color: rgb(62, 61, 61);
  }