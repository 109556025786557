.profile-card {
    width: 400px;
    padding: 20px;
    margin: auto;
    text-align: center;
    border-radius: 5px;
    background: linear-gradient(to right, #0D071C, #475193);
    box-shadow: 0 4px 15px 0 rgba(83,178,163,0.15);
    transition: box-shadow 0.3s ease;
}

.profile-card:hover {
    box-shadow: 0 8px 30px 0 rgba(83,178,163,0.25);
}

.profile-image {
    width: 40%;
    height: auto;
    border-radius: 50%;
}

.profile-name {
    font-size: 20px;
    font-weight: bold;
    color: #2d4059; /* Dark Blue */
}

.profile-email {
    font-size: 16px;
    font-weight: 600;
    color: #ea5455; /* Dark Red */
}

.profile-subscription {
    margin-top: 20px;
    font-size: 16px;
    color: #333;
}

.logout-button, .unsubscribe-button {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.logout-button:active, .unsubscribe-button:active {
    transform: scale(0.95);
}

.logout-button {
    background-color: #008CBA; /* Blue */
    color: white;
}

.logout-button:hover {
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.24);
}

.unsubscribe-button {
    background-color: #f44336; /* Red */
    color: white;
}

.unsubscribe-button:hover {
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.24);
}
