.header{
    background-color:#fff;
    position: relative;
    height: 65px;
    justify-content: space-between;
    align-items: center;
    top: 0;
    width: 100%;
    z-index: 10;
    box-shadow: 0px 10px 5px -9px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 6px 5px -9px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 5px -9px rgba(0,0,0,0.75);
}

@font-face {
    font-family: "Logo font";
    src: url("../../fonts/JigmaLogo.ttf") format("truetype");
}

.wrapper_grid{
    /*grid-template-columns: 10% 75% 15%;*/
    height: 100%;
  display: flex;
  flex-direction: row;
  /*justify-content: space-between;*/
  align-items: center;
  width: 100%;
  
}
.one {
    display: flex;
  }
.two {
    display: inline-flex;
    position: relative;
    transition: all 0.5s ease;
    width: 30%;
    justify-content: center;
}
.three {
    text-align: right;
    margin-left: auto;
}

.title{
    color: black;
    text-align: center;
    margin-left: -50px;
    padding-top: .8rem;
    width: 100vw;
    font-family: 'Archivo', sans-serif;
     
}

.logo{
    background: linear-gradient(to right, #0d6efd, #1cc7d0, #00c2a8, #7fcdff, #616161);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: left;
    font-size: 1.6rem;
    width: 15%;
    height: 3rem;
    min-width: 100px;
    padding-top: 0.2rem;
    font-family: "Logo font", sans-serif;
     
}

.coin-profile-container {
  display: inline-flex;
  align-items: center;
  padding-right: 0px;
}

.coin-icon {
  color: rgb(77, 166, 255);
  font-size: 2rem;
}

.coin-number {
  color: #616161;
  font-family: 'Archivo', sans-serif;
}

.coin-icon:hover {
  scale: 1.1;
}

.coin-number:hover {
  scale: 1.1;
}

.toggle-icon-header {
  font-size: 1.8rem;
  margin-left: 5px;
  margin-top: 5px;
    transition: all 0.5s ease;
    position: relative;
    color: #969494;
    transition: all 0.5s ease;
    cursor: pointer;
}

a svg {
    height: 5rem;
    width: 5rem;
    padding-top: 1.2rem;
    padding-left: 1.5rem;
    transform: scale(0.6);
}

.search {
    width: 100%;
    position: relative;
    margin-left: 1rem;
    display: flex;
  }
  
  .searchTerm {
    width: 100%;
    border: 1px solid #c8c8c8;
    font-size: 0.9rem;
    border-right: none;
    margin-bottom: 5px;
    padding: 5px;
    height: 35px;
    border-radius: 5px 0 0 5px;
    outline: none;
    color: #a4a4a4;
  }
  
  .searchTerm:focus{
    color: #323232;
  }
  
  .searchButton {
    width: 40px;
    height: 35px;
    border: 1px solid #c8c8c8;
    background: #848484;
    text-align: center;
    color: #eeeeee;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px;
  }
  
  .searchButton:hover {
    width: 40px;
    height: 35px;
    border: 1px solid #c8c8c8;
    background: #8484848f;
    text-align: center;
    color: #fff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px;
  }

  /* Buy more credits button */

  .buy-credit-button {
    background: linear-gradient(to right, #0d6efd, #1cc7d0, #00c2a8);
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    margin: 20px;
    color: #FFFFFF;
    cursor: pointer;
    flex-shrink: 0;
    font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
    font-size: 18px;
    font-weight: 500;
    height: 3rem;
    padding: 1.6rem;
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
    transition: all .5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .buy-credit-button:hover {
    box-shadow: rgba(80, 63, 205, 0.8) 0 5px 15px;
    transition-duration: .1s;
  }
  
  @media (min-width: 768px) {
    .buy-credit-button {
      padding: 0 2.6rem;
    }
    
  }
  @media (max-width: 768px) {
    .buy-credit-button {
      display: none;
    }  
  }
  
  /*Resize the wrap to see the search bar change!*/
  .wrap{
    width: 100%;
    position: relative;
    top: 40%;
  }

  @media all and (max-width:500px) {
    .two {
      width: 50%;
    }
    
  }