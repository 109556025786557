


.card_models{
    border-radius: 15px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 50px -17px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 50px -17px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 50px -17px rgba(0,0,0,0.75);
}
.title_card{
    color: gray;   
    font-weight: bold;
}
.sub_title_card{
    color: gray;   
}
