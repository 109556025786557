
.card_signup{
    border-radius: 10px;
    background-color: white;
}

.card_title{
    color: black;
    text-align: center;
    font-weight: bold;
}

.bottom_button{
    margin-top: 10rem;
    text-align: center;
}

.button_text{
    text-decoration: none;
    font-weight: bold;
    
}