.rounded_image_model{
    width:2.0rem;
    height:2.0rem;
    border-radius:150px;
    background-color: rgb(26, 26, 27);
}
.item_model_chat{
    /* border-bottom: solid 2px;
    border-color: rgb(224, 224, 224); */
    padding-top: 5px;
    text-align: left;
    cursor: pointer;
}

.chat-card {
    border-bottom: #d1d1d1 0.2px solid;
}

.chat-card-inner {
    width:190px;
}

.image_card_model{
   width: 10rem;
}

.title_item_chat{
    color: rgb(69, 69, 69);
    font-size: 0.8rem;
    font-family: 'Archivo', sans-serif;
    
}
.subtitle_item_chat{
    color: rgb(105, 105, 105);
    font-size: 0.7rem;
    font-family: 'Archivo', sans-serif;
}

.chat_active{
    background: linear-gradient(to right, #dfdfdf, #f4f4f4);
}

.trash-container {
    display: flex;
    width: 75px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: #f4f4f4;
 
}

.trash_btn{
    padding-right: 5px;
    color: rgb(115, 115, 115);
    /*border: 1px solid rgb(126, 241, 241);*/
    height: 1.2rem;
    width: 1.2rem;
    font-size: 1.5rem;
    cursor: pointer;
}

.trash_btn:hover{
    color: rgb(37, 137, 237);
}

.i[title]:hover:after {
    content: attr(title);
    display: block;
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translate(5px, -50%);
    padding: 5px;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
    font-size: 12px;
  }
  
  @media all and (max-width:500px) {
    .trash-container{
        margin-left: 0px;
    }
    .chat-card-inner {
        width: 80vw;
    }
}