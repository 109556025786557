.app-web{
    text-align: center;
    display: flex;
    flex-direction: row;
    background-color: #fbfcfe;
    color: white;
    position: absolute;
    top: 0;bottom: 0;left: 0;right: 0;
    overflow: hidden;
}

.content-static {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

.content-dynamic {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.sidemenu{
    width: 280px;
    background-color: #fbfcfe;
    position: relative;
    height: 100%;
    transition: all 0.5s ease;
}

.model-container {
    background-color: #fbfcfe;
    padding: None;
    margin: None;
    width: 280px;
    transition: all 0.5s ease;
}

.chatbox {
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    border: 10px solid white;
    background-color: white;
    position: relative;
    display: flex;
    outline: None;
    border: #e2e2e2 0.2px solid;

}

.chat-window {
   
    height: 85vh; /* set height to 80% of viewport height */
    max-height: 85vh;
    
}

.chat-input-holder {
    padding: 6px;
    position: absolute;
    outline: None;
    bottom: 10px;
    width: 97%;
}
.chat-input-holder-reduced {
    padding: 6px;
    position: absolute;
    outline: None;
    bottom: 10px;
    width: 92%;
}

.chat-input-textarea {
    /*background-color: #40414f;*/
    background-color: rgba(244, 244, 244, 0.95);
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    max-height: 150px;
    padding: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    border: None;
    margin: 5px;
    padding-right: 10%;
    outline: None;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
    color: black;
}

.button_send_chat{
    position: absolute;
    font-size: 1.8rem;
    color: #506fd9;
    right: 1.2rem;
    bottom: 1.4rem;
    transition: transform 0.2s ease-in-out;
}

.button_send_chat:hover{
    transform: scale(1.1);
}

.button-mic-transcription {
    position: absolute;
    color: black;
    font-size: 1.8rem;
    right:3.7rem;
    bottom: 1.4rem;
    transition: transform 0.2s ease-in-out;
}

.button-mic-transcription:hover {
    transform: scale(1.1);
}

.btn-purple {
    width: 90%;
    background: linear-gradient(to bottom, #5b41b8, #a02bbf);
    border-color: #5b41b8;
    color: #fff;
  }
  
.btn-purple:hover {
    opacity: 0.8;
    color: black;
  }

.subtitle-models {
    font-size: 0.9rem;
    text-align: left;
    padding: 2px;
}
.button-train {
    font-size: 0.8rem;
    font-style: oblique;
}

.title_modal{
    color: black;
}

.content_modal{
    color: rgb(63, 63, 63);
}

.modal-ok-button{
    background: var(--1, linear-gradient(90deg, #7122FF 0%, #8643FF 100%));
    color: white;
}

.modal-ok-button:hover{
    background: var(--1, linear-gradient(90deg, #6f22ffd7 0%, #8543ffd9 100%));
}

.modal-color {
    background: linear-gradient(to right, #f9f9f9, #e4e4e4);
    border-radius: 10px;
}

.model-nav-bar {
    background-color: #fbfcfe;
    align-items: start;
    align-content: start;
    min-width: 70px;
    transition: all 0.5s ease;
   
}

.toggle-icon {
    background-color: #fbfcfe;
    color: #969494;
    font-size: 1.8rem;
    transition: all 0.5s ease;
    cursor: pointer;
}

.toggle-button {
    background-color: #fbfcfe;
}


@media all and (max-width:500px) {
    .chat-input-holder {
        right: 1.5rem;
        width: 95vw;
    }
    .install-button {
        position: absolute;
        bottom:60px;left: 35vw;
    }
    .chat-input-textarea {
        padding-right: 20px;
        font-size: 12px;
        width: 100%;
    }
    .button-mic-transcription {
        right: 1.3rem;
        scale: 0.7;
    }
    .button_send_chat {
        right: 0rem;
        scale: 0.7;
    }
    .sidemenu {
        width: 100%;
    }
    .model-nav-bar {
        width: 100%;
    }
    .model-container {
        width: 100%;
    }
}

/* CSS */
.button-28 {
  appearance: none;
  background-color: transparent;
  border: 1px solid #969494;
  border-radius: 15px;
  box-sizing: border-box;
  color: #3B3B3B;
  cursor: pointer;
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 80%;
  will-change: transform;
}

.button-28:disabled {
  pointer-events: none;
}

.button-28:hover {
  color: #fff;
  background-color: #1A1A1A;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button-28:active {
  box-shadow: none;
  transform: translateY(0);
}

.add-model-icon {
    font-size: 1.2rem;
}

.add-model-icon-chat {
    color: rgb(248, 248, 248);
    font-size: 1.7rem;
}

.center-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.circle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px; /* Adjust the width and height as per your preference */
    height: 70px;
    border-radius: 5%;
    background: var(--1, linear-gradient(90deg, #7122FF 0%, #8643FF 100%));
    border: none;
    cursor: pointer;
  }

.circle-button:hover{
    background: var(--1, linear-gradient(90deg, #6f22ffd7 0%, #8543ffd9 100%));
}  

.model-content {
    align-content: center;
}

.create-text {
    color: #e8e8e8;
    margin-left: 3px;
}

.add-model-text {
    font-size: 0.9rem;
    padding-inline-start: 3px;
    font-family: 'Archivo', sans-serif;
    
}

.model-selector {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    left: 30px;
    width: 85%;
    height: 0px;
    margin-top: 30px;
    margin-bottom: -40px;
    color: #2b2a2a;
}

.model-dropdown {
    position: absolute;
    background-color: #e9e9e9;
    margin-left: 20px;
    width: 80%;
}