body{
}

.img_home{
    height: 12rem;
    width: auto !important;
}

.home{
    margin-top: 8rem;
}

.logo-image {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.font-inter {
  font-family: 'Inter', sans-serif;
}
.title_home{
    background: linear-gradient(to right, #0d6efd, #1cc7d0, #00c2a8, #7fcdff, #616161);
    background-clip: content-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: 100%;
    padding: 25px;
    margin-bottom: 10px;
    font-family: "Logo font", sans-serif;
    font-size: 4.0rem;
    font-weight: bold;
}

.login-signup {
    

}

.button_home_signup{
    background-color: black;
    color: white;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.6rem 4rem;
    text-align: center;
}
.button_home_login{
    background-color: blue;
    color: white;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.6rem 4rem;
    text-align: center;
    margin-top: 5px;
}


.signup {
    background-image: linear-gradient(#1f1f1f, #050607) !important;
}

/* CSS */
.button-login-signup {
  align-items: center;
  background-color: initial;
  background-image: linear-gradient(#4893e8, #0056c0);
  border-radius: 8px;
  border-width: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .1),0 3px 6px rgba(0, 0, 0, .05);
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: bold;
  height: 52px;
  justify-content: center;
  line-height: 1;
  margin: 2px;
  outline: none;
  overflow: hidden;
  padding: 0 32px;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 150ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-login-signup:hover {
  box-shadow: rgba(0, 1, 0, .2) 0 2px 8px;
  opacity: .85;
}

.button-login-signup:active {
  outline: 0;
}

.button-login-signup:focus {
  box-shadow: rgba(0, 0, 0, .5) 0 0 0 3px;
}

@media (max-width: 420px) {
  .button-login-signup {
    height: 30px;
  }
}