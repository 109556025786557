/* For Webkit browsers like Chrome, Safari */
::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background: #888; /* color of the scroll thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* color of the scroll thumb on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* "auto" or "thin"  */
  scrollbar-color: #888 #f1f1f1; /* thumb and track color */
}

body {
    margin: 0;
    padding: 0;
    font-family: Prompt;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  /* Header styles */
  
  .App-header {
    background-color: #18132F;  /* A color that harmonizes with #0D071C */
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    position: relative;
  }
  
  .header-left, .header-right {
    display: flex;
    align-items: center;
    margin-inline: 2rem;
  }

  .menu-button {
    display: none;
    background-color: #18132F;
    border: none;
  }
  
  .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  .menu-items {
    position: absolute;
    top: 100%;
    right: 0;
    gap: 20px;
    background: #18132F;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    display: none;
    z-index: 2; /* Layer it above the overlay */
  }
  
  .menu-items.active {
    display: flex;
  }

  .hamburger-icon{
    color: #f9f9f9;
    background-color: #18132F;
    font-size: 2rem;
  }

  .coin-desktop, .coin-mobile{
    display: flex;
    justify-items: center;
    align-content: center;
    margin-right: 10px;
  }

  .coin-number{
    margin-top: 5px;
    color: #fff;
  }

  .pricing-link {
    margin-right: 20px; /* Add some spacing between the link and the button */
    color: #fff; /* Set the text color */
    text-decoration: none; /* Remove underline */
    margin-bottom: 0px;
    font-weight: bold; /* Make it bold */
    cursor: pointer; /* Cursor style */
  }

  .pricing-link:hover{
    color: #0056b3;
  }

  .share-link {
    margin-right: 20px; /* Add some spacing between the link and the button */
    color: #313030; /* Set the text color */
    text-decoration: none; /* Remove underline */
    font-weight: normal; /* Make it bold */
    cursor: pointer; /* Cursor style */
  }
  
  
  .cta-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .cta-button:hover{
    background-color: #007bff88;
  }

  .not-display{
    display: none;
  }

  .cta-button:disabled {
    background-color: grey;
    cursor: not-allowed;
  }

  .profile-container-header {
    position: relative;
    display: inline-block;
  }
  
  .profile-icon {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: white;  /* change to fit your theme */
  }
  
  .profile-dropdown {
    position: absolute;
    right: -10px;
    top: 100%;
    background-color: #ffffff;
    border: 0.5px solid #6d6d6db9;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    padding: 10px;
    transition: opacity 0.4s ease, visibility 0.4s ease, max-height 0.4s ease;
    max-height: 0;  /* Adjust based on your actual maximum height */
    opacity: 0;
    visibility: hidden;
  }
  
  /* When hidden */
  .profile-dropdown.hidden {
    opacity: 1;
    visibility: visible;
    max-height: 1000px;
    overflow: hidden;
     /* Makes sure the hidden elements can't be interacted with */
  }
  
  .profile-dropdown label {
    display: block;
    padding: 10px;
    color: #161616;
    cursor: pointer;
  }
  
  .profile-dropdown label:hover {
    background-color: rgba(118, 59, 246, 0.942);
    color: white;
  }

  /* Main content styles */
  
  .App-main {
    flex: 1;
    display: flex;
    background: linear-gradient(to bottom, #0D071C, #18132F);
    padding: 1rem;
    color: #fff;
  }

  /* Side configurations */
  
  .sideContent {
    flex-basis: 30%;
    padding: 20px;
  }

  .sideContent-button {
    flex-basis: 30%;
    padding: 20px;
  }
  
  .preview {
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    width: 70%;
  }

  .container-personalization {
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: opacity 0.6s ease, visibility 0.6s ease, max-height 0.6s ease;
    max-height: 1700px;  /* Adjust based on your actual maximum height */
    opacity: 1;
    visibility: visible;
  }
  
  /* When hidden */
  .container-personalization.hidden {
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    overflow: hidden;
    pointer-events: none;  /* Makes sure the hidden elements can't be interacted with */
  }

  /* Dropdown */
  
  .chat-selector {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    left: 30px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #303030;
  }

  .chat-selector-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .chat-selector-container button{
    background-color: #f82e2e;
    border: none;
    border-radius: 5px;
    margin-top: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 6px;
  }

  .chat-selector-container button:hover{
    background-color: #f82e2eb7;
  }

  .trash-alt-icon{
    font-size: 1.3rem;
    color: #efefef;
    cursor: pointer;
  }

  .chat-dropdown1 {
    background-color: #e9e9e9;
    margin-top: 10px;
    width: 260px!important;
    min-width: 150px;
    height: 50px;
  }

  .assistant-label {
    display: flex;
    color: white;
    font-size: 1rem;
    margin-bottom: 15px;
  }

  /* Dropbox */

  .drop-box1 {
    border: 2px dashed #fff;
    background-color: #0894ff;
    color: white;
    padding: 20px;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
  }

.drop-box1:hover {
    background-color: #0894ffba;
}

.text-gym1 {
    color: rgb(234, 234, 234);
    font-family: 'Inter', sans-serif;
}
  
  .text-dropzone {
    color: #fff;
    font-size: 16px;
  }

  .upload-file-card3 {
    background-color:#18102d;
    width: 100%;
    height: 70px;
    border-radius: 5px;
    border: 0.5px solid #d9d9d992;
    display: inline-flex;
    align-items: center;
    padding: 10px;
}

.text-gym-file1 {
    color: rgb(251, 251, 251);
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
}

.file-icon1 {
    font-size: 1.5rem;
    color: rgb(200, 200, 200);
}

.x-icon1 {
    font-size: 2rem;
    color: #bdbdbd;
}

.train-free-button{
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 30px;
}

/* Iframe styles */

.container-iframe-share {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  transition: opacity 0.3s ease, visibility 0.3s ease, max-height 0.3s ease;
  max-height: 1400px;  /* Adjust based on your actual maximum height */
  opacity: 1;
  visibility: visible;
}

/* When hidden */
.container-iframe-share.hidden {
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
  pointer-events: none;  /* Makes sure the hidden elements can't be interacted with */
}

  .iframe-container {
    position: relative;
    width: 70%;
   
    margin-top: 10px;
    border-radius: 15px;
  }
  
  .responsive-iframe {
    
    border-radius: 15px;
    width: 100%;
    height: 580px;
    border: none;
  }

  /* Share options */

  .get-link {
    width: 80%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 20px;
  }

  .get-link p{
    color: black;
  }
  
  .link-container,
  .qr-container {
    margin-top: 15px;
  }
  
  .share-link-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    width: 80%;
    text-overflow: ellipsis;
  }

  .separator {
    height: 0.5px;  /* Thickness of the line */
    background-color: rgba(108, 107, 107, 0.531);  /* Color of the line */
    margin-top: 10px;  /* Space above the line */
    margin-bottom: 10px;  /* Space below the line */
  }
  
  .url-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .copy-button {
    margin-left: 10px;
  }
  
  .qr-section {
    margin-top: 10px;
  }
  
  /* Grid for personalities */

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-top: 20px;
  }
  
  .grid-item {
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #acacac3b;
    background-color: #fff;
    padding: 10px;
    cursor: pointer;
  }

  .grid-item small {
    color: #0D071C;
    margin-top: 5px;
    font-size: 0.7rem;
    font-weight: 500;
  }

  .grid-item,.hero small {
    color: #ffffff;
    margin-top: 5px;
    font-size: 0.7rem;
    font-weight: 500;
  }

  .hero{
    background-color: #6836e4;
  }

  .selected {
    border: 2px solid #3f81ec; /* Choose the border color you like */
    scale: 0.98;
  }
  
  .grid-item img {
    width: 60px;  /* set width */
    height: 60px; /* set height */
    object-fit: cover; /* resize image to cover the container while maintaining its aspect ratio */
  }

  /* Custom personality */

  .custom-textarea-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom-textarea-container h5 {
   padding-bottom: 20px;
}

.custom-textarea-container textarea {
    width: 100%;
    max-width: 500px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    font-size: 1em;
    line-height: 1.5em;
    resize: vertical; /* allows the user to vertically resize the textarea */
}

  
  .App-footer {
    background-color: #0D071C;  /* Same as header for consistency */
    padding: 1rem;
    text-align: center;
    color: #a7a7a7;
  }

  .email-link {
    color: #fff;  /* Same as footer text for consistency */
    text-decoration: underline;  /* Helps indicate it's a clickable email link */
  }

  /* Reset section */

  .sideContent-reset-section {
    background: linear-gradient(45deg, #6836e4, #a199ff); /* 45-degree gradient from #6836e4 to #a199ff */
    padding: 40px;
    border-radius: 12px;
    height: 250px;
    text-align: center;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.reset-icon i {
    font-size: 4rem;
    color: white;
}

h2 {
    color: white;
    font-size: 2rem;
    margin: 10px 0;
}

.sideContent-reset-section p {
    color: white;
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.cta-button1 {
    background-color: #007bff;
    color: white;
    padding: 15px 30px;
    border-radius: 8px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: none; /* Remove default border */
    outline: none; /* Remove focus outline */
}

.cta-button1:hover {
    background-color: #0056b3;
}
/* Tab for drop and input text */

.enter-data-container{
  padding: 20px;
  border: 1px solid #0056b3;
  border-radius: 5px;
  background-color: #f4f4f4;
}

.tab-menu {
  display: flex;
  justify-content: start;
  margin-bottom: 20px;
  width: 100%;
}

.tab-menu button {
  flex: 1;  /* This will make each button take up an equal amount of space in the container */
  background-color: #f4f4f4;
  border: none;
  border-bottom: 1px solid #565657;
  color: black;
  padding: 12px 16px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}

.tab-menu button:hover {
  background-color: #ddd;
}

.active-tab {
  background-color: #555;
  color: white;
}

.chatbot-preview-skeleton {
  border: 1px solid #ccc;
  padding: 20px;
  background-color: rgb(75 85 99);
  width: 60%;
  height: 550px; /* Adjust as per your design */
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chatbot-preview-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.chatbot-preview-body{
  width: 100%;
}

.chatbot-preview-body p {
  text-align: center;
  color: #fafafa;
  font-style: italic;
}

.iframe-youtube{
  width: 100%;
  height: 320px;
}

/* Footer.css */

.footer {
  display: flex;
  background-color: #0D071C;
  padding: 20px 40px;
  color: white;
}

.footer-logo {
  font-size: 24px;
}

.footer-section {
  width: 15%;
}

.footer-section h2 {
  margin-bottom: 12px;
  font-size: 1.2rem;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 8px;
  font-size: 1rem;
}

.footer-section ul li a {
  color: rgb(147, 147, 147);
  text-decoration: none;
}

.footer-section-email{
  position: absolute;
  right: 25px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  min-width: 550px;
  justify-content: space-between;
  margin-top: 10px;
}

.footer-section-email h4 {
  font-size: 1.2rem;
}

.footer-section-email form {
  display: flex;
  flex-direction: column;
}

.footer-section-email input[type="email"] {
  padding: 10px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  width: 420px;
}

.footer-section-email button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #5a5a5a;
  color: white;
  cursor: pointer;
}

.footer-section-email button:hover {
  background-color: #7a7a7a;
}

.whatsapp-icon-jigma{
  background-color: #09D261;
  border: none;
  border-radius: 50%;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.twitter-icon-jigma{
  background-color: #00ACEE;
  border: none;
  border-radius: 50%;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.facebook-icon-jigma{
  background-color: #3B5998;
  border: none;
  border-radius: 50%;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.social-links{
  display: flex;
}

  
  /* Responsive */
  @media (max-width: 768px) {
    .App-main {
      flex-direction: column;  /* Stack in column on small screens */
      padding: 1rem;
    }
    .sideContent, .preview {
      flex-basis: 100%;  /* Full width on small screens */
    }

    .iframe-container{
        width: 100%;
    }

    .sideContent-reset-section{
      height: 300px;
    }

    .chat-selector{
      width: 300px;
    }

    .get-link{
      width: 90%;
      padding: 10px;
    }

    .menu-button {
      display: block;
    }
  
    .menu-items {
       /* Hide menu items on mobile */
      display: flex;
      opacity: 0;
      visibility: hidden;
      max-height: 0;
      
    }
  
    .menu-items.active {
      display: flex;  /* Show menu items when active */
      transition: opacity 0.4s ease, visibility 0.4s ease, max-height 0.4s ease;
      max-height: 1000px;  /* Adjust based on your actual maximum height */
      opacity: 1;
      visibility: visible;
    }

    .coin-desktop{
      display: none;
    }

    .url-section{
      flex-direction: column;
      gap: 10px;
    }

    .preview{
      width: 100%;
    }

    .chatbot-preview-skeleton{
      width: 100%;
    }

    .iframe-youtube{
      height: 200px;
    }

    .footer{
      gap: 40px;
    }
    
    .footer-section-email{
      display: none;
    }
    .footer-section{
      width: 30%;
    }
  }

  @media (min-width: 769px) {
    .menu-items {
      display: flex; /* Make sure the menu items are displayed on desktop */
      flex-direction: row;
      align-items: center;
      position: static; /* Reset positioning */
      background: none; /* Remove background */
      padding: 0; /* Remove padding */
    }
  
    .menu-button {
      display: none; /* Hide the hamburger menu on desktop */
    }
  
    .menu-overlay {
      display: none; /* Hide the overlay on desktop */
    }

    .coin-mobile{
      display: none;
    }
  }
  