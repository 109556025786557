
.card_title {
  font-size: 1.2rem;
}

.card_pre_gym{
    border-radius: 5px;
    background-color: white;
    border: #dadada;
}
.card_sub_title_modal{
    font-size: 0.9rem;
    color: gray;
    font-weight: bold;
    text-align: left;
    padding: 10px 0;
}
.link_pre_modal{
    text-decoration: none;
    color: blue;
    margin: 20px 0;
    font-weight: bold;
}




/* CSS */
.button-train-pregym {
  display: flex;
    width: 100%;
    padding: 14px 20px;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 8px;
    background: var(--1, linear-gradient(90deg, #7122FF 0%, #8643FF 100%));
    cursor: pointer;
}

.button-train-pregym:hover {
  background: var(--1, linear-gradient(90deg, #6f22ffd7 0%, #8543ffd9 100%));
}

.button-train-text{
  color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0;
    cursor: pointer;
}

.button-goback{
  display: flex;
    width: 100%;
    padding: 14px 20px;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    cursor: pointer;
}

.button-goback-text{
  color: #313131;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    margin-bottom: 0;
    cursor: pointer;
}

.button-goback:hover{
  border: 1px solid #dcdcdc!important;
  background: #efefef;
}

.button-train-pregym:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-train 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-train {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-train-pregym:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}