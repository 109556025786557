.chatbot-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2E7FF1;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  border-radius: 5px;
}

.chatbot-title-container {
  display: flex;
  align-items: center;
}

.chatbot-logo {
  height: 32px;
  width: 32px;
}

.chatbot-title {
  margin: 0;
  font-size: 1rem; /* Decrease the size */
  color: rgba(255, 255, 255, 0.95);
  font-family: 'Open Sans', sans-serif;
}

.chatbot-status {
  text-align: left;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.75);
  margin-top: 2px;
}

.chatbot-buttons button {
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  background-color: #2E7FF1;
  color: #fff;
  cursor: pointer;
}

.chatbot-buttons button:hover {
  opacity: 0.6;
}
