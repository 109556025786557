.content-dynamic-pregym{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow-y: scroll;
}

.upload-file-card {
    background-color: grey;
    border-radius: 20px;
}

.pre-gym-box {
    border: rgb(218, 215, 215) 0.2px solid ;
}
