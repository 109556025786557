.forgot{
    margin-top: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box{
    
}

.title_fg{
    font-size: 2rem;
    color: black;
    font-weight: bold;
    text-align: left;
}

.sub_title_fg{
    padding: 1rem 0;
    width: 33rem;
    font-size: 1rem;
    color: gray;
    text-align: left;

}

.cancel_fg{
    color: gray;
    text-decoration: none;
    text-align: center;
    margin: .8rem auto;
    display: block;
}