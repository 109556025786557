.App-analytics{
    flex: 1;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom, #0D071C, #18132F);
    padding: 1rem;
    color: #fff;
}

.top-analytics{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ai-report-panel {
    width: 60%; /* adjust this value as needed */
    margin-left: auto; /* pushes the container to the right */
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.report-container {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    background-color: #291c3c;
    padding: 1rem 2rem;
    border-radius: 8px;
    width: 100%; /* make it occupy the full width of ai-report-panel */
}

.report-content {
    display: flex;
    flex-direction: column;
    flex: 1; 
    align-items: center;
    padding-right: 20px; /* adds some space between the text and the video */
    gap: 10px;
}

.video-content {
    flex: 1.5; /* this makes the video a bit wider than the report content */
}

.video-content iframe {
    width: 100%; 
    height: auto; 
    min-height: 300px;
    border: none;
}


.charts-container{
    display: flex;
    flex-direction: row;
    align-self: center;
    padding: 25px;
    gap: 10px;
    width: 100%;
    background-color: #291c3c;
    border-radius: 10px;
    align-items: center;
}

.chat-selector{
    width: 250px;
}

@keyframes glowingEffect {
    0% {
      box-shadow: 0 0 4px #B2A3FF, 0 0 8px #B2A3FF;
    }
    100% {
      box-shadow: 0 0 8px #B2A3FF, 0 0 12px #B2A3FF;
    }
}

.ai-button {
    background: linear-gradient(45deg, #6200EE, #B2A3FF);
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: 600;
    display: flex;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.ai-icon {
    font-size: 25px;
    display: inline-block;
    margin-right: 8px;
    transition: transform 0.4s ease;
}

.ai-button:hover .ai-icon {
    transform: rotate(-20deg); /* Rotate the wand slightly on hover */
}

.ai-button:active .ai-icon {
    transform: rotate(0deg);
}

.ai-button:hover {
    transform: scale(1.05); /* Slight scale up on hover */
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2); /* Increased shadow for a "lifted" effect */
    animation: glowingEffect 1.2s infinite alternate;
}

.ai-button:active {
    transform: scale(1); /* Reset to original size when button is pressed */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Reset shadow to original when button is pressed */
}

  

@media (max-width: 768px) {
    .top-analytics{
        flex-direction: column;
    }
    .ai-report-panel{
        margin-left: 0;
        width: 100%;
    }
    .report-container{
        flex-direction: column;
        gap: 20px;
    }
    .charts-container{
        width: 100%;
        flex-direction: column;
    }

}