.delete_file{
    color: red;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

.upload-file-card2 {
    background-color: rgb(194, 224, 255);
    border-radius: 2px;
    display: inline-flex;
    align-items: center;
    padding: 10px;
}

.file-icon {
    font-size: 2.0rem;
    color: rgb(79, 79, 250);
}

.x-icon {
    font-size: 1.5rem;
}

.text-gym {
    color: rgb(23, 23, 23);
    font-family: 'Inter', sans-serif;
}

.text-gym-file {
    color: rgb(23, 23, 23);
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
}

.text-dropzone {
    font-family: 'Inter', sans-serif;
    color: rgb(62, 61, 61);
  }

.drop-box {
    border: 2px dashed #ccc;
    padding: 20px;
    margin: 20px;
    text-align: center;
    color: black; /* Change the text color to black */
    cursor: pointer;
  }

.drop-box:hover {
    background-color: rgb(198, 216, 253);
}

.upload-icon {
    font-size: 2.5rem;
}

.separator-line {
    border-left: 1px solid #ccc;
    height: 100%;
  }

.button-train{
    width: 100%;
    padding: 14px 20px;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 8px;
    background: var(--1, linear-gradient(90deg, #7122FF 0%, #8643FF 100%));
    cursor: pointer;
}

.button-train:hover{
     background: var(--1, linear-gradient(90deg, #6f22ffd7 0%, #8543ffd9 100%));
}