.item_chat_model-question {
    display: flex;
  flex-direction: column;
  align-items: flex-end;
    
}

.item_chat_model-answer {
    display: flex;
  flex-direction: column;
  align-items: flex-start;
    
}

.box_chat{
    border-radius: 8px;
    height: auto;
    max-width: 75%;
    margin-left: auto; 
    margin-right: 18px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px;
    padding: 15px 10px;
    background-color: #506fd9;
}

.box_chat2{
    border-radius: 8px;
    height: auto;
    max-width: 75%;
    margin-left: 18px; 
    margin-right: auto;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px;
    padding: 15px 10px;
    background-color: #f3f5f9;
}

.input-text-user {
    color: #fff;
    font-weight: lighter;
    left: 0;
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    text-align: right;
}

.input-text-ai {
    color: #575757;
    overflow-x: visible;
    font-family: 'Inter', sans-serif;

    left: 0;
    width: 100%;
    font-size: 0.8rem;
    text-align: left;

}

.bottom_text_chat_item_model_question{
    color: gray;
    padding-right: 2rem;
    display: flex;
  justify-content: flex-end;
    font-size: .6rem;
    padding-top: 5px;
}

.bottom_text_chat_item_model_answer{
    color: gray;
    padding-left: 1rem;
    display: flex;
  justify-content: flex-start;
    font-size: .6rem;
    padding-top: 5px;
}

.icon_question_container{
    text-align: left;
    
}

.icon_question{
    float: right;
    /* background: radial-gradient( rgba(9,9,121,1) 10%, rgb(246, 248, 248) ); */
    border-radius: 100%;
    /* height: 5rem; */
    /* border: 2px solid black; */
    border-radius: 100%;
    position: relative;
    display: block;
    height: 60px;
    width: auto;
}


.text_ans{
    text-align: right;
}
.icon_ans{
    float: left;
    /* background: radial-gradient( rgba(9,9,121,1) 10%, rgb(246, 248, 248) ); */
    border-radius: 100%;
    padding: None;
    margin: None;
    position: relative;
    display: block;
    height: 60px;
    width: auto;
}

.icon_ans_container{
    text-align: center;
}

.bg_chat_model{
    /* background: rgb(234, 236, 236); */
    /* background: linear-gradient(0deg, rgba(192,200,200,1) 0%, rgba(120,116,109,1) 100%); */
    /* border: 1px solid gray; */
    border-top-right-radius: 2%;
    border-bottom-right-radius: 10%;
    color: gray;
    height: 100%;
    padding-left: 5px;
}

@media all and (max-width:500px) {
    .box_chat{
        width: 90%;
    }
    .box_chat2{
        width: 90%;
    }
    .input-text-user {
        font-size: 12px;
    }
    .input-text-ai {
        font-size: 12px;
    }
}