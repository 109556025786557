.content-dynamic-customize{
  display: flex;
  overflow-y: scroll;
}
  
.control-panel {
  flex: 7;
  width: 70%;
  margin-bottom: 0px;
  height: 100vh;
  color: rgb(113, 113, 113);
}

.chat-preview {
  flex: 3;
  width: 30%;
  height: 50vh;
  color: rgb(113, 113, 113);
}

/* Create Panel */

.create-model {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: #dbdbdb 1px solid;
  margin: 10px;
}

.upload-box{
  flex: 4;
  width: 40%;
  align-self: flex-end;
}

.model-info {
  flex: 5;
  width: 50%;
  align-self: flex-start;
}

  
/* Chat preview styles */

.export-chat-header-preview {
 
  padding: 0.5rem;
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-chat-preview {
  font-size: 1.5rem;
  position: relative;
  align-self: flex-end;
  cursor: pointer;
}

.chatbox1-preview {
  box-sizing: border-box;
  background-color: white;
  position: relative;
  outline: None;
  
}

.box-chat-container-preview {
  border: #f3f3f3 0.1px solid;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: none;
}

.chat-window1-preview {
 
  height: 100vh; /* set height to 80% of viewport height */
  max-height: 100vh;
  
}

.chat-input-holder1-preview {
  padding-left: 0.6rem;
  position: absolute;
  bottom: 0;
  outline: None;
  width: 95%;
}

.chat-input-textarea1-preview {
  /*background-color: #40414f;*/
  background-color: rgba(255, 255, 255, 0.95);
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-size: 0.7rem;
  max-height: 150px;
  padding: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  border: None;
  margin: 5px;
  padding-right: 10%;
  outline: None;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  color: black;
}

.button_send_chat-preview{
  position: absolute;
  font-size: 1.4rem;
  color: #506fd9;
  right: 0.5rem;
  bottom: 1.2rem;
  transition: transform 0.2s ease-in-out;
}

.button_send_chat-preview:hover{
  transform: scale(1.1);
}

.button-mic-transcription-preview {
  position: absolute;
  color: black;
  font-size: 1.4rem;
  right:2.2rem;
  bottom: 1.2rem;
  transition: transform 0.2s ease-in-out;
}

.button-mic-transcription-preview:hover {
  transform: scale(1.1);
}

/* Control Panel */

.control-panel {
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 1000px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
 
}

.control-panel-title {
  font-family: 'Open sans', sans-serif;
  font-size: 1.1rem;
  font-weight: light;
  align-self: flex-start;
  margin-bottom: 1.5rem;
}

.control-panel-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.control-panel-row label {
  font-family: 'Open sans', sans-serif;
  display: flex;
  font-size: 0.9rem;
  margin-right: 10px;
}

.control-panel-row input[type='color'] {
  height: 30px;
  width: 50px;
  font-family: 'Inter', sans-serif;
  border-radius: 50px;
  border: none;
}

.control-panel-row input[type='file'] {
  height: 50px;
  font-size: 1rem;
  width: 100%;
  border: none;
}

.control-panel-row input[type='text'] {
  height: 50px;
  width: 100%;
  border-radius: 5px;
  border: none;
  padding: 5px;
}

.logo-input {
  width: 200px;
  height: 70px;
  font-size: 1rem;
}

.chat-title-input-preview {
  font-size: 0.8rem !important;
  font-family: 'Open sans', sans-serif !important;
  border: #dbdbdb 1px solid !important;
  width: 30% !important;
  height: 30px !important;
}

.chat-personality-input-preview {
  font-size: 0.8rem !important;
  font-family: 'Open sans', sans-serif !important;
  border: #dbdbdb 1px solid !important;
  width: 30% !important;
  height: 30px !important;
}

.chat-welcome-input-preview {
  font-size: 0.8rem !important;
  font-family: 'Open sans', sans-serif !important;
  border: #dbdbdb 1px solid !important;
  width: 30% !important;
  height: 30px !important;
}

.logo-preview-container {
  height: 5rem;
}

.embed-title {
  align-self: flex-start;
  padding: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 0.9rem;
  padding-bottom: 10px;
}

.embed-code-box {
  width: 100%;
  height: 150px;
}

.script-container {
  position: relative;
  width: 70%;
}

.script-js-area {
    width: 100%;
    min-Height: '200px';
    font-Family: 'Consolas, monospace';
    font-size: '14px';
    line-Height: '1.5';
    border: '1px solid #ccc';
    border-Radius: '15px';
}

.copy-js-button {
    position: relative;
    font-family: 'Open sans', sans-serif;
    top: 10px;
    right: 10px;
    padding: 8px;
    font-Size: 16px;
    background-Color: #4CAF50;
    color: white;
    border: none;
    border-Radius: 4px;
    cursor: pointer;
}

.assistant-label {
  font-family: 'Open sans', sans-serif;
  display: flex;
  font-size: 0.9rem;
  margin-right: 10px;
  margin-top: 5px;
}

.chat-selector {

  display: inline-flex;
  align-items: left;
  justify-content: left;
  left: 30px;
  width: 400px;
  margin-top: 20px;
  color: #2b2a2a;
}

.chat-dropdown {
  background-color: #e9e9e9;
  margin-left: 10px;
  width: 200px;
  min-width: 150px;
}

@media all and (max-width:500px) {
  .chatbot-outside-container {
      display: none;
  }
}

.upgrade-plan {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: rgba(0,0,0,0.3);
  font-family: 'Poppins', sans-serif;
}

.upgrade-plan-content {
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.15);
  background-color: #fff;
  width: 70%;
  max-width: 500px;
  text-align: center;
}

.upgrade-image {
  width: 100%;
  max-width: 200px;
  margin-bottom: 20px;
}

.upgrade-plan h2 {
  color: #333;
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 10px;
}

.upgrade-plan p {
  color: #666;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.upgrade-button {
  color: #fff;
  background-color: #3e8ff1; /* Bright green */
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  position: relative;
  overflow: hidden;
}

.upgrade-button:hover {
  transform: scale(1.05); /* Slightly enlarge the button */
  background-color: #2385fc; /* Darker green */
}

.upgrade-button:after {
  content: "";
  background: #fff;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s
}

.upgrade-button:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}




