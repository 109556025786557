.content{
  display: flex;
width: 100%;
padding: 90px 210px 72px 210px;
flex-direction: column;
align-items: center;
z-index: 100;
}

.text-content{
  display: flex;
flex-direction: column;
align-items: center;
gap: 40px;
}

.ultimate-no-code{
  display: flex;
flex-direction: column;
align-items: center;
gap: 32px;
}

.hero-text{
  display: flex;
flex-direction: column;
align-items: center;
gap: 16px;
}

.ai-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ai-assistant-can{
  width: 60vw;
  color: #FFF;
text-align: center;
font-size: 4rem;
font-family: Prompt;
}

.no-code-text{
  color: #FFF;
text-align: center;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 125% */
}

.pricing-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.no-code-text{
  color: #FFF;
  text-align: center;
  width: 70%;
  margin-bottom: -90px;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
}

.pricing-content{
  display: flex;
  align-items: flex-start;
  height: 98vh;
  width: 100vw;
  gap: 24px;
  scale: 0.8;
}

.pricing-items1{
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  order:1;
}

.pricing-items2{
  display: flex;
  padding: 48px 48px 52px 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  order: 2;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 16px 72px 0px rgba(22, 0, 50, 0.10);
}

.first-item-container{
  display: flex;
  padding: 48px 150px 52px 48px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 16px 72px 0px rgba(22, 0, 50, 0.10);
}

.first-item-content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.second-item-container{
  display: flex;
  padding: 40px 85px 40px 48px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 16px 72px 0px rgba(22, 0, 50, 0.10);
}


.pricing-free-plan-content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.free-plan-description{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
}

.free-plan-title{
  display: flex;
  padding: 12px 20px;
  align-items: center;
  gap: 12px;
  border-radius: 48px;
  background: #E7FFEF;
}

.free-plan-title .title-text{
  color: #26A651;
  font-family: Prompt;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 24px */
}

.free-plan-description .description-text{
  color: #3D3949;
  font-family: Inter;
  width: 80%;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}

.messages-per-month{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.pricing-get-started-button{
  display: flex;
  width: 100%;
  padding: 20px 24px;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 10px;
  border-radius: 6px;
  background: var(--1, linear-gradient(90deg, #7122FF 0%, #8643FF 100%));
  cursor: pointer;
}

.pricing-get-started-button:hover{
  background: var(--1, linear-gradient(90deg, #6f22ffbc 0%, #8543ffbc 100%));
}

.pricing-started-button-text{
  color: #FFF;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 132%; /* 23.76px */
}

.number-messages{
  font-family: Prompt;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 64px */
  background: var(--1, linear-gradient(90deg, #7122FF 0%, #8643FF 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.incomming-messages{
  color: #98979A;
  font-family: Prompt;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.per-month{
  color: #0D071C;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 125% */
}

.ai-arrow{
  display: flex;
  align-items: flex-start;
  position: relative;
  right: 23px;
  bottom: 160px;
}


/* Second item */

.second-item-content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
}

.second-item-title{
  color: #252033;
  font-family: Prompt;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 36.4px */
}

.second-item-description{
  color: #3D3949;
  font-family: Inter;
  font-size: 20px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}

.pricing-get-in-touch{
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 10px;
  border-radius: 6px;
  background: var(--6, linear-gradient(90deg, #2253FF 0%, #43D2FF 100%));
  cursor: pointer;
}

.pricing-get-in-touch:hover{
  background: var(--6, linear-gradient(90deg, #2252ffcb 0%, #43d3ffe1 100%));
}

.pricing-get-in-touch-text{
  color: #FFF;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 18px */
}

/* Third Item */

.pricing-items2-content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 52px;
}

.pricing-calculator{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.pricing-details{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.monthly-pricing-title{
  display: flex;
  padding: 12px 20px;
  align-items: center;
  gap: 12px;
  border-radius: 48px;
  background: #D9F8FF;
}

.monthly-pricing-title .title-text{
  color: #26A651;
  font-family: Prompt;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 24px */
}

.slider-values{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 48%;
  margin-bottom: -20px;
}

.slider-values .text{
  color: #98979a;
  font-family: "Inter-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
}

.slider-container{
  width: 100%;
}

.pricing-slider{
  width: 100%;
}

.pricing-additional-messages{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.pricing-jigma-messages{
  display: flex;
  width: 532px;
  justify-content: space-between;
  align-items: flex-start;
}

.pricing-additional-messages .text1{
  color: #3D3949;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 24px */
}

.pricing-additional-messages .text2{
  color: #0D071C;
  text-align: right;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 83.333% */
}

.pricing-jigma-messages .text1{
  color: #3D3949;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 24px */
}

.pricing-jigma-messages .text2{
  color: #0D071C;
  text-align: right;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 83.333% */
}

.pricing-items2-features{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.pricing-items2-features .feature1{
  display: flex;
  align-items: center;
  gap: 24px;
}

.pricing-items2-features .feature1-text{
  color: #3D3949;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 20px */
}

.redeem-code-container {
  width: 100%; /* Full width of the parent */
  display: flex; /* Use flexbox */
  justify-content: space-between; /* Space out the buttons equally */
  gap: 20px;
}

@media (max-width: 1040px) {

  .content{
    padding: 40px 50px 72px 50px;
  }

  .pricing-container{
      width: 100%;
      margin-bottom: 260%;
      position: relative;
      left: -10px;
  }

  .pricing-content{
      flex-direction: column;
      width: 100%;
  }

  .pricing-items1{
      order: 2;
      width: 100vw;
  }

  .first-item-container{
      padding: 50px;
  }

  .first-item-content{
      width: 100%;
  }

  .ai-assistant-can{
    font-size: 2rem;
    font-weight: 800;
  }

  .no-code-text{
    font-size: 1rem;
  }

  .free-plan-description .description-text{
      width: 90vw;
  }

  .second-item-description{
    width: 80%;
  }

  .pricing-items2{
      order: 1;
      width: 100vw;
  }

  .pricing-jigma-messages{
      width: 100%;
  }

  .slider-values{
      gap: 9vw;
  }

  .pricing-get-started-button{
      width: 100%;
  }

  .pricing-slider{
      width: 80vw;
  }
}